import { observable, action, makeObservable } from 'mobx'
import { UUIDUtils } from '../../utils/UUIDUtils'
import { IRecipeCategoryDTO } from '../dtos/IRecipeCategoryDTO'
import { serialize, deserialize, serializable } from 'serializr'

export class RecipeCategory implements IRecipeCategoryDTO {
  static create(boardId, recipeCategoryGuid: string = null) {
    const cat = new RecipeCategory()
    cat.RecipeCategoryGuid = recipeCategoryGuid
    if (!recipeCategoryGuid) cat.RecipeCategoryGuid = UUIDUtils.generateUUID()
    cat.BoardId = boardId
    cat.isNew = true
    return cat
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public RecipeCategoryGuid: string = ''
  @serializable @observable public BoardId: number = 0
  @serializable @observable public Name: string = ''
  @serializable @observable public IsDeleted: boolean = false
  public isOnServer: boolean = false
  public isNew: boolean = false

  @action
  public setName(val: string) {
    this.Name = val
  }

  public markIsOnServer(): void {
    this.isOnServer = true
  }

  public markIsNotOnServer(): void {
    this.isOnServer = false
  }

  public clone(): RecipeCategory {
    return deserialize(RecipeCategory, serialize(this))
  }

  public toDTO(): IRecipeCategoryDTO {
    return serialize(this)
  }
}
