import React, { useEffect } from 'react'
import {
  IonList,
  IonItem,
  IonButton,
  IonLabel,
  IonInput,
  IonSpinner,
  IonTitle,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonToolbar,
  IonText,
  IonCardSubtitle,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItemDivider,
  IonToast,
} from '@ionic/react'
import { observer, inject, useLocalObservable } from 'mobx-react'
import { BoardInvitationsStore } from '../store/BoardInvitationsStore'
import { BoardInvitationNewModalVM as BoardInvitationNewVM } from '../view-models/BoardInvitationNewVM'
import { RootStore } from '../../stores/RootStore'
import { arrowBack, checkmark, copy } from 'ionicons/icons'
import PanelCard from '../../shared/panel/PanelCard'
import { AppStore } from '../../stores/AppStore'

interface Props {
  boardInvitationsStore?: BoardInvitationsStore
  rootStore?: RootStore
  appStore?: AppStore
}

const BoardInvitationNewPage: React.FC<Props> = ({ rootStore, appStore }) => {
  useEffect(() => {
    appStore.setCurrentRoute('/invite')
  }, [appStore])

  const vm = useLocalObservable(() => new BoardInvitationNewVM(rootStore))

  const handleSaveClick = (e) => {
    e.preventDefault()
    vm.save()
  }

  const handleCancelClick = (e) => {
    e.preventDefault()
    vm.goBack()
  }

  const renderSpinner = () => {
    if (!vm.isProcessing) return
    if (vm.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  const renderButtons = () => {
    if (vm.isProcessing) return
    return (
      <div id='buttons'>
        <IonButton type='button' size='small' color='secondary' onClick={handleCancelClick}>
          Cancel
        </IonButton>
        <IonButton type='submit' size='small' color='tertiary' disabled={!vm.isValid}>
          Invite
        </IonButton>
      </div>
    )
  }

  const renderBlankHeader = () => {
    if (!rootStore.appStore.showAppHeader) return
    return (
      <IonHeader>
        <IonToolbar />
      </IonHeader>
    )
  }

  const renderCopiedToast = () => (
    <IonToast
      isOpen={vm.copiedToastShown}
      onDidDismiss={() => vm.copiedToastHidden()}
      message='Copied to clipboard'
      duration={1500}
    />
  )

  return (
    <>
      {renderBlankHeader()}
      <IonHeader id='BoardInvitationNewPage_Header'>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={() => vm.goBack()} tabIndex={-1}>
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Invite</IonTitle>
          <IonButtons slot='end'>
            <IonButton type='submit' onClick={() => vm.save()} tabIndex={-1} disabled={false}>
              <IonIcon icon={checkmark} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        style={{ height: rootStore.appStore.listHeightPx }}
        ref={(e) => rootStore.appStore.applyScrollStyles(e)}
        id='BoardInvitationNewPage_Content'
      >
        <PanelCard maxWidth='600px' noMarginBottom>
          <IonTitle>Invite</IonTitle>
          <IonText>Share the following code with your friend.</IonText>
          <div id='InvitationCode'>
            <div id='Code'>
              {vm.invitationCode}
              <IonButton fill='clear' size='small' color='tertiary' onClick={() => vm.copyCode()}>
                <IonIcon icon={copy} />
              </IonButton>
            </div>
          </div>
          <div className='or'>OR</div>
          <IonText>
            Pop in the email of your future board buddy!
            <br />
            They'll get a snazzy invite to join the fun.
          </IonText>
          <form onSubmit={handleSaveClick}>
            <IonList>
              <IonItem lines={'inset'}>
                <IonLabel position='stacked'>Email</IonLabel>
                <IonInput
                  type='email'
                  className='ion-input-md'
                  autocomplete='off'
                  value={vm.toEmailAddress}
                  onIonChange={(e: any) => vm.setToEmailAddress(e.target.value)}
                />
              </IonItem>
              {renderButtons()}
              {renderSpinner()}
              {renderCopiedToast()}
            </IonList>
          </form>
        </PanelCard>
      </IonContent>
    </>
  )
}

export default inject('boardInvitationsStore', 'rootStore', 'appStore')(observer(BoardInvitationNewPage))
