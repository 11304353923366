import React from 'react'
import { IonList, IonItem, IonButton, IonLabel, IonItemDivider, IonInput, IonIcon } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { BoardsStore } from '../store/BoardsStore'
import { copy, refresh } from 'ionicons/icons'
import { BoardEditVM } from '../view-models/BoardEditVM'

interface Props {
  boardsStore?: BoardsStore
  vm?: BoardEditVM
}

const InvitationCodeRow: React.FC<Props> = ({ vm }) => {
  const renderNoInvitationCode = () => {
    if (vm.hasInvitationCode) return
    return (
      <IonLabel>
        <IonButton color='primary' onClick={() => vm.generateInvitationCode()}>
          Generate
        </IonButton>
      </IonLabel>
    )
  }

  const renderInvitationCode = () => {
    if (!vm.hasInvitationCode) return
    return (
      <>
        <IonInput
          type='text'
          readonly
          placeholder='Enter Code'
          name='jsakasdflfhdjkl'
          value={vm.invitationCode}
          autocomplete='off'
          onFocus={(e: any) => e.nativeEvent.target.select()}
        />
        <div slot='end'>
          <IonButton color='primary' onClick={() => vm.generateInvitationCode()}>
            <IonIcon icon={refresh} />
          </IonButton>
          <IonButton color='tertiary' onClick={() => vm.copyInvitationCode()}>
            <IonIcon icon={copy} />
          </IonButton>
        </div>
      </>
    )
  }

  return (
    <IonList mode='md'>
      <IonItemDivider color='medium' mode='md'>
        Invitation Code
      </IonItemDivider>
      <IonItem>
        {renderNoInvitationCode()}
        {renderInvitationCode()}
      </IonItem>
    </IonList>
  )
}

export default inject('boardsStore')(observer(InvitationCodeRow))
