import { inject } from 'mobx-react'
import Icon from '../../assets/images/Icon_512.png'
import './LogoHeader.scss'
import { AppStore } from '../../stores/AppStore'

interface Props {
  appStore?: AppStore
}

const LogoHeader: React.FC<Props> = ({ appStore }) => {
  return (
    <div id='LogoHeader'>
      <div onClick={() => appStore.navigateTo('/login')}>
        <div id='g'>Grocery</div>
        <div id='b'>Board</div>
      </div>
      <img src={Icon} alt='Grocery Board' />
    </div>
  )
}

export default inject('appStore')(LogoHeader)
