import { RootStore } from '../../stores/RootStore'
import { BoardInvitation } from '../../board-invitations/aggregate/BoardInvitation'
import { action, computed, makeObservable } from 'mobx'

export class BoardInvitationRowVM {
  private boardInvitation: BoardInvitation
  private rootStore: RootStore

  constructor(rootStore: RootStore, inv: BoardInvitation) {
    this.rootStore = rootStore
    this.boardInvitation = inv
    makeObservable(this)
  }

  @computed
  public get key(): string {
    return this.boardInvitation.BoardInvitationGuid
  }

  @computed
  public get boardName(): string {
    return this.boardInvitation.BoardName
  }

  @action
  public openBoardInvitation() {
    this.rootStore.boardInvitationsStore.loadAcceptVM(this.boardInvitation.BoardInvitationGuid)
  }
}
