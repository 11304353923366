import React, { ReactNode } from 'react'
import { IonCard, IonCardContent } from '@ionic/react'
import './PanelCard.scss'

interface PanelCardProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  maxWidth?: string
  centered?: boolean
  noMarginBottom?: boolean
}

const PanelCard: React.FC<PanelCardProps> = ({ children, maxWidth, centered, noMarginBottom }) => {
  if (!maxWidth) maxWidth = ''
  const textAlign = centered ? 'center' : 'left'
  const marginBottom = noMarginBottom ? '0' : '200px'

  return (
    <div id='PanelCard' style={{ textAlign, marginBottom }}>
      <div className='body' style={{ maxWidth }}>
        {children}
      </div>
    </div>
  )
}

export default PanelCard
