import React from 'react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react'
import { AppUpdateAvailableCardVM } from '../view-models/AppUpdateAvailableCardVM'
import { RootStore } from '../../stores/RootStore'
import { EvilIcons } from 'react-web-vector-icons'

interface Props {
  rootStore?: RootStore
}

const AppUpdateAvailableCard: React.FC<Props> = ({ rootStore }) => {
  const vm = useLocalObservable(() => new AppUpdateAvailableCardVM(rootStore))
  if (!vm.hasUpdateAvailable) return null

  return (
    <IonCard id='AppUpdateAvailableCard' color='secondary'>
      <IonCardHeader color='dark'>
        <IonCardTitle>App Update</IonCardTitle>
        <EvilIcons name='arrow-up' size={29} />
      </IonCardHeader>

      <IonCardContent>
        Don't miss out! &nbsp; Stay up to date with all of the latest features and enhancements.
        <br />
        <IonButton color='tertiary' onClick={() => vm.updateNow()}>
          Update Now
        </IonButton>
      </IonCardContent>
    </IonCard>
  )
}

export default inject('rootStore')(observer(AppUpdateAvailableCard))
