import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import { Board } from '../aggregate/Board'
import { BoardsService } from '../service/BoardsService'
import { BoardUserRowVM } from './BoardUserRowVM'
import { Clipboard } from '@capacitor/clipboard'

export class BoardEditVM {
  constructor(private rootStore: RootStore) {
    makeObservable(this)
  }

  @computed
  public get board(): Board {
    return this.rootStore.boardsStore.currentBoard
  }

  @observable public deleteConfirmShown: boolean = false
  @observable public copiedToastShown: boolean = false
  @observable public inviteModalShown: boolean = false

  @action
  public closeInviteModal() {
    this.inviteModalShown = false
  }

  @computed
  public get name(): string {
    if (!this.board) return ''
    return this.board.Name
  }

  @computed
  public get isValid(): boolean {
    if (!this.name || this.name === '') return false
    return true
  }

  @computed
  public get hasInvitationCode(): boolean {
    return Boolean(this.board.InvitationCode)
  }

  @computed
  public get invitationCode(): string {
    return this.board.InvitationCode
  }

  @action
  public openJoinBoardModal() {
    this.rootStore.boardsStore.joinVM.toggle()
  }

  @computed
  public get boardUsers() {
    return this.board.BoardUsers.map((e) => new BoardUserRowVM(this.rootStore, this, e))
  }

  @action
  public async copyInvitationCode() {
    try {
      await Clipboard.write({
        string: this.invitationCode.toString(),
      })
      runInAction(() => (this.copiedToastShown = true))
    } catch (e) {
      alert(e)
      console.error(e)
    }
  }

  @action
  public copiedToastHidden() {
    this.copiedToastShown = false
  }

  @action
  public async generateInvitationCode() {
    if (window.Offline.state === 'down') {
      alert('Internet connection not found. Please try again later')
      return
    }
    const svc = new BoardsService(this.rootStore)
    await svc.generateInvitationCodeOnServer(this.board.toDTO())
  }

  @computed
  public get pendingBoardInvitations() {
    return this.board.BoardInvitations.filter((e) => e.StatusId === 1)
  }

  @action
  public invite() {
    this.rootStore.appStore.navigateTo('/invite')
  }

  @action
  public setName(val) {
    this.board.setName(val)
  }

  @action
  public save() {
    const svc = new BoardsService(this.rootStore)
    svc.saveBoard(this.board.toDTO())
  }

  @action
  public goBack() {
    this.rootStore.appStore.handleGoBack()
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  @computed
  public get canDeleteBoard() {
    return this.rootStore.boardsStore.persistedRecords.length > 1
  }

  @action
  public delete() {
    const svc = new BoardsService(this.rootStore)
    this.board.markAsDeleted()
    svc.saveBoard(this.board.toDTO())
    const str = this.rootStore.boardsStore
    str.setCurrentBoard(
      str.persistedRecords.find((e) => e.BoardId !== this.rootStore.boardsStore.currentBoardId).BoardId
    )
    this.rootStore.appStore.handleGoBack()
  }
}
