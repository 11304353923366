import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import GuestUserPageContent from './GuestUserPageContent'
import { AppStore } from '../../../stores/AppStore'

interface Props {
  appStore?: AppStore
}

const GuestUserPage: React.FC<Props> = ({ appStore }) => {
  useEffect(() => {
    appStore.setCurrentRoute('/guestuser')
  }, [appStore])

  return <GuestUserPageContent />
}

export default inject('appStore')(observer(GuestUserPage))
