import React from 'react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonText,
  IonTitle,
  IonToast,
  IonToolbar,
} from '@ionic/react'
import { checkmarkCircle, logIn, mail } from 'ionicons/icons'
import { UserStore } from '../../store/UserStore'
import { RootStore } from '../../../stores/RootStore'
import BackIcon from '../../../shared/BackIcon'
import { GuestUserPageVM } from '../../view-models/GuestUserPageVM'
import { AntDesign } from 'react-web-vector-icons'
import RegisterModal from './RegisterModal'
import PanelCard from '../../../shared/panel/PanelCard'

interface Props {
  rootStore?: RootStore
  userStore?: UserStore
}

const GuestUserPageContent: React.FC<Props> = ({ rootStore }) => {
  const vm = useLocalObservable(() => new GuestUserPageVM(rootStore))

  const renderBlankHeader = () => {
    if (!rootStore.appStore.showAppHeader) return
    return (
      <IonHeader>
        <IonToolbar />
      </IonHeader>
    )
  }

  const renderContinueWithGoogle = () => {
    return (
      <IonButton className='google-button' expand='full' type='button' color='dark' onClick={() => vm.connectGoogle()}>
        <AntDesign name='google' size={20} />
        &nbsp; Continue with Google
      </IonButton>
    )
  }

  const renderContinueWithApple = () => {
    if (!vm.canSignInWithApple) return
    return (
      <IonButton className='apple-button' expand='full' type='button' color='dark' onClick={() => vm.connectApple()}>
        <AntDesign name='apple1' size={20} />
        &nbsp; Continue with Apple
      </IonButton>
    )
  }

  const renderRegisterByEmail = () => {
    return (
      <IonButton
        className='email-button'
        color='medium'
        expand='full'
        type='button'
        onClick={() => vm.openRegisterModal()}
      >
        <IonIcon icon={mail} />
        &nbsp; Register by Email
      </IonButton>
    )
  }

  const renderOr = () => {
    return <IonTitle className='or'>OR</IonTitle>
  }

  const renderLoginButton = () => {
    return (
      <IonButton className='login-button' color='medium' type='button' onClick={() => vm.goToLogin()}>
        <IonIcon icon={logIn} />
        &nbsp; Sign in here
      </IonButton>
    )
  }

  const renderWelcomeMessage = () => {
    return <IonTitle className='welcome-message'>Welcome Guest</IonTitle>
  }

  const renderEncouragement = () => {
    return <IonText className='encouragement'>{vm.message} </IonText>
  }

  const renderAlreadyRegistered = () => {
    return (
      <IonText className='already-registered'>
        Been here before? Sign in to your existing account to pick up where you left off.
      </IonText>
    )
  }

  const renderRegisterModal = () => {
    if (!vm.registerModalShown) return
    return <RegisterModal onClose={(success) => vm.closeRegisterModal(success)} />
  }

  const renderToast = () => {
    return (
      <IonToast
        cssClass='success-toast'
        color='success'
        isOpen={vm.toastShown}
        message='Thank you for registering!'
        position='bottom'
        duration={3000}
        icon={checkmarkCircle}
      />
    )
  }

  return (
    <>
      {renderBlankHeader()}
      <IonHeader id='GuestUserPage_Header'>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={() => vm.goBack()}>
              <BackIcon />
            </IonButton>
          </IonButtons>
          <IonTitle>Register</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        id='GuestUserPage_Content'
        style={{ height: rootStore.appStore.listHeightPx }}
        ref={(e) => rootStore.appStore.applyScrollStyles(e)}
      >
        <PanelCard maxWidth='600px'>
          {renderWelcomeMessage()}
          {renderEncouragement()}
          {renderContinueWithGoogle()}
          {renderContinueWithApple()}
          {renderOr()}
          {renderRegisterByEmail()}
          {renderAlreadyRegistered()}
          {renderLoginButton()}
        </PanelCard>
      </IonContent>
      {renderToast()}
      {renderRegisterModal()}
    </>
  )
}

export default inject('rootStore', 'userStore')(observer(GuestUserPageContent))
