import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { RootStore } from '../stores/RootStore'
import Agent from '../Agent'

export class RecoverAccountVM {
  constructor(private rootStore: RootStore) {
    makeObservable(this)
  }

  @observable public errorMessages: string[] = []
  @observable public isProcessing: boolean = false
  @observable public toastShown: boolean = false
  @observable public code: string = ''
  @observable public token: string = ''

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }
  
  public goToHelp() {
    window.open('https://groceryboard.io/contact')
  }

  @action
  private showToast() {
    this.toastShown = true
  }

  @computed
  public get inputDisabled(): boolean {
    return this.isProcessing || this.toastShown
  }

  @action
  public async tryCode(e: any) {
    e.preventDefault()
    this.clearErrorMessages()
    this.isProcessing = true
    const result: string[] = await Agent.Users.tryRecoveryCode({ code: this.code })
    runInAction(() => {
      if (!Array.isArray(result)) {
        this.errorMessages.push('An unknown error occurred.')
        this.isProcessing = false
        return
      }
      if (result.length === 0) {
        this.errorMessages.push('An unknown error occurred.')
        this.isProcessing = false
        return
      }
      if (result.length === 1) {
        this.errorMessages.push(result[0])
        this.isProcessing = false
        return
      }
      if (result[0] === 'Success') {
        this.showToast()
        this.token = result[1]
      }
    })
  }

  @action
  public goToResetPassword() {
    this.rootStore.appStore.navigateTo('/tno/' + this.token)
  }

  @action
  private clearErrorMessages() {
    this.errorMessages = []
  }

  public goToLogin() {
    this.rootStore.appStore.navigateTo('/login')
  }

  public goBack() {
    this.rootStore.appStore.navigateTo('/forgot-password')
  }

  @action
  public setCode(val) {
    if (!val) return
    if (val === 'undefined') return
    if (val.length > 6) val = val.substring(0, 6)
    this.code = val
    this.clearErrorMessages()
  }
}
