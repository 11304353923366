import { persist } from 'mobx-persist'
import { observable, action, makeObservable, computed } from 'mobx'
import { UUIDUtils } from '../../utils/UUIDUtils'
import { Step } from './Step'
import { Category } from './Category'
import { RecipeItem } from './RecipeItem'
import { IRecipeDTO } from '../dto/IRecipeDTO'
import { serialize, deserialize, serializable, list, object } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'
import { Attachment } from './Attachment'

export class Recipe implements IRecipeDTO, IAggregrate {
  public static create(boardId: number, recipeGuid: string = null) {
    const rcp = new Recipe()
    rcp.RecipeGuid = recipeGuid
    if (!recipeGuid) rcp.RecipeGuid = UUIDUtils.generateUUID()
    rcp.BoardId = boardId
    rcp.addRecipeItem()
    rcp.addStep()
    return rcp
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public RecipeGuid: string = ''
  @serializable @observable public Name: string = ''
  @serializable @observable public LinkUrl: string = ''
  @serializable @observable public Notes: string = ''
  @serializable @observable public BoardId: number = 0
  @serializable(list(object(Step))) @observable public Steps: Array<Step> = []
  @serializable(list(object(Category))) @observable public Categories: Array<Category> = []
  @serializable(list(object(RecipeItem))) @observable public RecipeItems: Array<RecipeItem> = []
  @serializable @observable public IsDeleted: boolean = false
  @serializable(list(object(Attachment))) @observable public Attachments: Attachment[] = []
  public isOnServer: boolean = false

  @action
  public addAttachment(storageFileGuid: string) {
    console.log(this.Attachments)
    const existing = this.Attachments.find((a) => a.StorageFileGuid === storageFileGuid)
    if (existing) return existing
    const attachment = Attachment.create(storageFileGuid, this.Attachments.length + 1)
    this.Attachments.push(attachment)
    return attachment
  }

  @action
  public addStep(rank: number = 1000): Step {
    const step = Step.create()
    step.setStepNumber(rank)
    if (rank !== -1) {
      const currentStepAtRank = this.Steps.find((e) => e.StepNumber === rank)
      if (currentStepAtRank) currentStepAtRank.setStepNumber(rank + 0.1)
    }
    this.Steps.push(step)
    this.orderSteps()
    return step
  }

  @action
  public addCategory(recipeCategoryGuid) {
    const cat = Category.create(recipeCategoryGuid)
    this.Categories.push(cat)
  }

  @action
  public addRecipeItem(itemGuid = null, rank: number = 1000): RecipeItem {
    const ri = RecipeItem.create(itemGuid)
    ri.setRank(rank)
    if (rank !== -1) {
      const currentItemAtRank = this.RecipeItems.find((e) => !e.IsDeleted && e.Rank === rank)
      if (currentItemAtRank) currentItemAtRank.setRank(rank + 0.1)
    }
    this.RecipeItems.push(ri)
    this.orderRecipeItems()
    return ri
  }

  @action
  public setName(val: string) {
    this.Name = val
  }

  @action
  public setLinkUrl(val: string) {
    this.LinkUrl = val
  }

  @action
  public setNotes(val: string) {
    this.Notes = val
  }

  public markIsNotOnServer(): void {
    this.isOnServer = false
  }

  @action
  public markIsOnServer(): void {
    this.isOnServer = true
  }

  @action
  public deleteCategory(categoryGuid: string) {
    const cat = this.getCategory(categoryGuid)
    cat.toggleDeleted()
  }

  @action
  public deleteStep(stepGuid: string) {
    const step = this.getStep(stepGuid)
    step.markAsDeleted()
    this.orderSteps()
  }

  @action
  public deleteRecipeItem(recipeItemGuid: string) {
    const recItem = this.getRecipeItem(recipeItemGuid)
    recItem.markAsDeleted()
    this.orderRecipeItems()
  }

  private orderSteps() {
    this.Steps.filter((e) => !e.IsDeleted)
      .sort((a, b) => (a.StepNumber < b.StepNumber ? -1 : 1))
      .forEach((e, idx) => e.setStepNumber(idx + 1))
  }

  private orderRecipeItems() {
    this.RecipeItems.filter((e) => !e.IsDeleted)
      .sort((a, b) => (a.Rank < b.Rank ? -1 : 1))
      .forEach((e, idx) => e.setRank(idx + 1))
  }

  public getRecipeItem(recipeItemGuid: string): RecipeItem {
    return this.RecipeItems.find((e) => e.RecipeItemGuid === recipeItemGuid)
  }

  public getCategory(catGuid: string): Category {
    return this.Categories.find((e) => e.CategoryGuid === catGuid)
  }

  public getCategoryForRecipeCategory(recipeCategoryGuid: string) {
    return this.Categories.find((e) => e.RecipeCategoryGuid === recipeCategoryGuid)
  }

  public getStep(stepGuid: string): Step {
    return this.Steps.find((e) => e.StepGuid === stepGuid)
  }

  public getRecipeItemForItem(itemGuid: string): RecipeItem {
    return this.RecipeItems.find((e) => e.ItemGuid === itemGuid && !e.IsDeleted)
  }

  public getRecipeItemsForItem(itemGuid: string): RecipeItem[] {
    return this.RecipeItems.filter((e) => e.ItemGuid === itemGuid && !e.IsDeleted)
  }

  @action
  public increaseRecipeItemQuantity(itemGuid: string) {
    let recipeItem = this.getRecipeItem(itemGuid)
    if (!recipeItem) recipeItem = this.addRecipeItem(itemGuid)
    recipeItem.increaseQuantity()
  }

  @action
  public decreaseRecipeItemQuantity(itemGuid: string) {
    let recipeItem = this.getRecipeItem(itemGuid)
    if (!recipeItem) recipeItem = this.addRecipeItem(itemGuid)
    recipeItem.decreaseQuantity()
  }

  @computed
  public get primaryAttachment() {
    return this.Attachments.slice().sort((a, b) => a.Rank - b.Rank)[0]
  }

  public getRecipeItemQuantity(itemGuid: string): number {
    const foundRecipeItem = this.getRecipeItemForItem(itemGuid)
    if (foundRecipeItem) return foundRecipeItem.Quantity
    return 0
  }

  public getAttachment(attachmentGuid: string): Attachment {
    return this.Attachments.find((a) => a.AttachmentGuid === attachmentGuid)
  }

  public clone(): Recipe {
    return deserialize(Recipe, serialize(this))
  }

  public toDTO(): IRecipeDTO {
    return serialize(this)
  }
}
