import { observable, makeObservable, action, computed } from 'mobx'
import { UUIDUtils } from '../../utils/UUIDUtils'
import { IStorageFileDTO } from '../dtos/IStorageFileDTO'
import { serialize, deserialize, serializable, list, object } from 'serializr'
import { StorageFileFormatEnum } from '../enums/StorageFileFormatEnum'

export class StorageFileFormat implements IStorageFileDTO {
  constructor() {
    makeObservable(this)
  }

  @serializable @observable public StorageFileGuid: string = ''
  @serializable public BoardId: number
  @serializable @observable public FileName: string
  @serializable @observable public MimeType: string
  @serializable @observable public Size: number
  @serializable @observable public UniqueFileName: string
  @serializable @observable public Extension: string
  @serializable @observable public Url: string
  @serializable @observable public ProviderName: string
  @serializable @observable public ProviderMetadataJson: string
  @serializable @observable public Format?: StorageFileFormatEnum
  @serializable @observable public Width?: number
  @serializable @observable public Height?: number
  @serializable @observable public Duration?: number
  @serializable @observable public IsDeleted: boolean = false
  @serializable @observable public isDownloaded: boolean = false
  @serializable @observable public AvailableOffline: boolean = false
  public isNew: boolean = false
  public isOnServer: boolean = false

  @computed
  public get hasSignedUrl(): boolean {
    return this.Url?.indexOf('sv=') >= 0
  }

  @action
  public setDuration(val: number) {
    this.Duration = val
  }

  @action
  public setAvailableOffline(val: boolean): void {
    this.AvailableOffline = Boolean(val)
  }

  public setFileName(fileName: string, uniqueFileName: string = undefined) {
    this.FileName = fileName
    if (uniqueFileName) this.UniqueFileName = uniqueFileName
    if (!uniqueFileName && !this.UniqueFileName) this.UniqueFileName = UUIDUtils.generateUUID() + '_' + fileName
  }

  public setMimeType(mimeType: string) {
    this.MimeType = mimeType
  }

  @action
  public markAsDownloaded(): void {
    this.isDownloaded = true
  }

  public markIsNotOnServer(): void {
    this.isOnServer = false
  }

  public markIsOnServer(): void {
    this.isOnServer = true
  }
}
