import { observable, action, computed, makeObservable } from 'mobx'
import { persist } from 'mobx-persist'
import { UUIDUtils } from '../../utils/UUIDUtils'

export class MealItem {
  public static create(itemGuid: string, recipeGuid: string = null, mealItemGuid = ''): MealItem {
    const mi = new MealItem()
    if (mealItemGuid !== '') mi.MealItemGuid = mealItemGuid
    else mi.MealItemGuid = UUIDUtils.generateUUID()
    mi.ItemGuid = itemGuid
    mi.RecipeGuid = recipeGuid
    mi.Quantity = 0
    mi.Rank = 1000
    return mi
  }

  constructor() {
    makeObservable(this)
  }

  @persist @observable public MealItemGuid: string = ''
  @persist @observable public ItemGuid: string = ''
  @persist @observable public RecipeGuid: string = null
  @persist @observable public Quantity: number = 0
  @persist @observable public Rank: number = 0
  @persist @observable public IsDeleted: boolean = false

  @action
  public setQuantity(val: number) {
    this.Quantity = val
  }

  @action
  public markAsDeleted() {
    this.IsDeleted = true
  }
}
