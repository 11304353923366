import React, { useEffect } from 'react'
import {
  IonContent,
  IonList,
  IonItem,
  IonInput,
  IonLabel,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonTitle,
  IonItemDivider,
  IonAlert,
  IonToast,
} from '@ionic/react'
import { observer, inject, useLocalObservable } from 'mobx-react'
import { AppStore } from '../../stores/AppStore'
import { checkmarkSharp, trashOutline } from 'ionicons/icons'
import { BoardsStore } from '../store/BoardsStore'
import BackIcon from '../../shared/BackIcon'
import BoardInvitationNewModal from '../../board-invitations/views/BoardInvitationNewPage'
import InvitationCodeRow from './InvitationCodeRow'
import BoardUserRow from './BoardUserRow'
import { BoardEditVM } from '../view-models/BoardEditVM'
import { RootStore } from '../../stores/RootStore'
import PanelCard from '../../shared/panel/PanelCard'

interface Props {
  appStore?: AppStore
  boardsStore?: BoardsStore
  history?: any
  rootStore?: RootStore
}

const BoardEditPage: React.FC<Props> = ({ rootStore, appStore, boardsStore, history }) => {
  const vm = useLocalObservable(() => new BoardEditVM(rootStore))

  useEffect(() => {
    appStore?.setCurrentRoute('/boardsettings')
  }, [appStore])

  const renderNameRow = () => (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        vm.save()
      }}
    >
      <IonList mode='md'>
        <IonItem lines={'inset'}>
          <IonLabel position='stacked'>Name</IonLabel>
          <IonInput type='text' value={vm.name} onIonChange={(e: any) => vm.setName(e.target.value)} />
        </IonItem>
      </IonList>
    </form>
  )

  const renderPendingInviationsHeader = () => {
    if (!vm.pendingBoardInvitations.length) return null
    return (
      <IonItemDivider color='medium' mode='md' style={{ marginTop: '18px' }}>
        Pending Invitations
      </IonItemDivider>
    )
  }

  const renderCopiedToast = () => (
    <IonToast
      isOpen={vm.copiedToastShown}
      onDidDismiss={() => vm.copiedToastHidden()}
      message='Copied to clipboard'
      duration={1500}
    />
  )

  const renderMembers = () => (
    <IonList mode='md'>
      <IonItemDivider color='medium' mode='md'>
        Members
      </IonItemDivider>
      {vm.boardUsers.map((row, idx) => (
        <BoardUserRow key={'usr' + idx} row={row} />
      ))}
      {renderPendingInviationsHeader()}
      {vm.pendingBoardInvitations.map((usr, idx) => (
        <IonItem lines={'inset'} key={'bi' + idx}>
          <IonLabel>
            <h2>{usr.ToEmailAddress}</h2>
          </IonLabel>
        </IonItem>
      ))}
      <IonItem lines={'inset'} onClick={() => vm.invite()}>
        <IonLabel>
          <IonButton color='primary'>Invite by Email</IonButton>
        </IonLabel>
      </IonItem>
    </IonList>
  )

  const renderDeleteConfirm = () => (
    <IonAlert
      isOpen={vm.deleteConfirmShown}
      onDidDismiss={() => vm.hideDeleteConfirm()}
      header={'Delete Board'}
      message={'Are you sure you wish to delete this board?'}
      buttons={[
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'primary',
          handler: () => vm.hideDeleteConfirm(),
        },
        {
          text: 'Yes',
          handler: () => vm.delete(),
        },
      ]}
    />
  )

  const renderDeleteButton = () => {
    if (!vm.canDeleteBoard) return null
    return (
      <IonButton onClick={() => vm.showDeleteConfirm()}>
        <IonIcon icon={trashOutline} />
      </IonButton>
    )
  }

  return (
    <>
      {appStore?.showAppHeader && (
        <IonHeader>
          <IonToolbar />
        </IonHeader>
      )}

      <IonHeader id='BoardEditPage_Header'>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={() => vm.goBack()}>
              <BackIcon />
            </IonButton>
          </IonButtons>
          <IonTitle>Board Settings</IonTitle>
          <IonButtons slot='end'>
            {renderDeleteButton()}
            <IonButton onClick={() => vm.save()}>
              <IonIcon icon={checkmarkSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        id='BoardEditPage_Content'
        style={{ height: appStore.listHeightPx }}
        ref={(e) => appStore.applyScrollStyles(e)}
      >
        <PanelCard maxWidth='600px' noMarginBottom>
          <IonItemDivider color='medium' mode='md'>
            Board Name
          </IonItemDivider>
          {renderNameRow()}
          <InvitationCodeRow vm={vm} />
          {renderMembers()}
          {renderDeleteConfirm()}
          {renderCopiedToast()}
        </PanelCard>
      </IonContent>
    </>
  )
}

export default inject('appStore', 'boardsStore', 'rootStore')(observer(BoardEditPage))
