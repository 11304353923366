class Environment {
  private static instance: Environment

  private constructor() {
    this.loadAppUrl()
    this.loadListRowHeight()
    this.loadIsDevMode()
    this.loadAppleCredentials()
    this.loadGoogleCredentials()
  }

  public appUrl: string
  public isDevMode: boolean
  public listRowHeight: number
  public appleClientId: string
  public appleRedirectUri: string
  public appleScopes: string
  public appleNonce: string
  public appleState: string
  public googleWebClientId: string

  private loadAppleCredentials(): void {
    if (!this.appUrl) this.loadAppUrl()
    this.appleClientId = process.env.REACT_APP_APPLE_CLIENT_ID
    this.appleScopes = process.env.REACT_APP_APPLE_SCOPES
    this.appleNonce = process.env.REACT_APP_APPLE_NONCE
    this.appleState = process.env.REACT_APP_APPLE_STATE
    this.appleRedirectUri = this.appUrl + '/login'
  }

  private loadGoogleCredentials(): void {
    this.googleWebClientId = process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID
  }

  private loadIsDevMode(): void {
    const val = process.env.REACT_APP_IS_DEV_MODE === '1'
    this.isDevMode = val
  }

  private loadAppUrl(): void {
    const thisDomain = window.location.origin
    this.appUrl = thisDomain
  }

  private loadListRowHeight(): void {
    this.listRowHeight = 50
    document.documentElement.style.setProperty('--list-row-height', this.listRowHeight + 'px')
  }

  public static getInstance(): Environment {
    if (!Environment.instance) Environment.instance = new Environment()
    return Environment.instance
  }
}

export default Environment.getInstance()
