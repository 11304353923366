import React from 'react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { RecipeImportVM } from '../view-models/RecipeImportVM'
import { RootStore } from '../../stores/RootStore'
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSpinner,
  IonText,
  IonTextarea,
} from '@ionic/react'
import { AppStore } from '../../stores/AppStore'
import { alertCircleOutline } from 'ionicons/icons'

interface Props {
  rootStore?: RootStore
  appStore?: AppStore
}

const RecipeImportContent: React.FC<Props> = ({ rootStore, appStore }) => {
  const vm = useLocalObservable(() => new RecipeImportVM(rootStore))

  const renderUrl = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Url link to recipe</IonLabel>
        <IonTextarea
          value={vm.url}
          autoGrow
          onIonChange={(e: any) => {
            vm.setUrl(e.target.value)
          }}
        />
      </IonItem>
    )
  }

  const renderSpinner = () => {
    if (!vm.isProcessing) return
    return (
      <div id='Processing'>
        <div id='SpinnerContainer'>{renderActualSpinner()}</div>
        {renderMessage()}
      </div>
    )
  }

  const renderActualSpinner = () => {
    if (vm.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  const renderMessage = () => {
    return (
      <IonText>
        <span>Extracing recipe, please wait...</span>
        <br />
        <br />
        Grocery Board uses AI to analyze and extract the recipe at the provided URL.
        <br />
        <br />
        This may take a few moments.
      </IonText>
    )
  }

  const renderError = () => {
    if (vm.status !== 'Error') return
    return (
      <div id='Error'>
        <IonIcon icon={alertCircleOutline} />
        <br />
        <IonText>
          <span>Unable to extract recipe</span>
          <br />
          <br />
          Grocery Board was unable to extract the recipe from the provided url.
          <br />
          <br />
          {vm.errorMessage}
          <br />
          <br />
          Please try another url.
          <br />
          <br />
          If you are still having trouble, feel free to contact <a href='https://groceryboard.io/contact'>support</a>.
        </IonText>
      </div>
    )
  }

  return (
    <IonContent
      id='RecipeImportPage_Content'
      scrollY
      ref={(e) => appStore.applyScrollStyles(e)}
      style={{ height: appStore.listHeightPx }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
          vm.submit()
        }}
      >
        <IonGrid>
          <IonRow>
            <IonCol sizeXs='12'>{renderUrl()}</IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeXs='12'>
              {renderSpinner()}
              {renderError()}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div id='buttons'>
                <IonButton type='button' size='small' color='secondary' onClick={() => vm.goBack()}>
                  Cancel
                </IonButton>
                <IonButton type='submit' size='small' color='tertiary' disabled={vm.importButtonDisabled}>
                  Import
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>
      <br />
      <br />
      <br />
      <br />
      <br />
    </IonContent>
  )
}

export default inject('rootStore', 'appStore')(observer(RecipeImportContent))
