import { observable, action, makeObservable, computed } from 'mobx'
import { BoardInvitation } from '../aggregate/BoardInvitation'
import { BoardInvitationEditVM as BoardInvitationAcceptVM } from '../view-models/BoardInvitationAcceptVM'
import { RootStore } from '../../stores/RootStore'
import { IBoardInvitationDTO } from '../dto/IBoardInvitationDTO'
import AgentV2 from '../../AgentV2'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import { DataStore } from '@elexient/elexiapp.bits.shared'

export class BoardInvitationsStore extends DataStore<RootStore, BoardInvitation, IBoardInvitationDTO> {
  protected worker: any

  constructor(rootStore: RootStore) {
    super(rootStore, BoardInvitation, AgentV2, 'BoardInvitations', 'BoardInvitation')
    super.setRecords(this.persistedRecords)
    makeObservable(this)
    makePersistable(this, { name: 'BoardInvitationsStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydated?: boolean) {
    this.persistedRecords = observable.array(this.persistedRecords.map((e) => makeObservable(deserialize(BoardInvitation, e))))
    this.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
  }

  @observable public isHydrated: boolean = false
  @observable.shallow public persistedRecords: Array<BoardInvitation> = []

  @observable public acceptVM: BoardInvitationAcceptVM = null

  @action
  public loadAcceptVM(guid) {
    const boardInv = this.get(guid)
    this.acceptVM = new BoardInvitationAcceptVM(this.rootStore, boardInv)
  }

  @computed
  public get currentBoardRecords() {
    return []
  }
}
