import { computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'

export class NativeButtonVM {
  constructor(private rootStore: RootStore) {}

  public redirectToPlayStore(): void {
    this.rootStore.appStore.navigateTo('/redirect/android')
  }

  public redirectToAppStore(): void {
    this.rootStore.appStore.navigateTo('/redirect/ios')
  }

  @computed
  public get appStoreButtonShown() {
    if (this.rootStore.appStore.isAndroid) return false
    if (this.rootStore.appStore.isNative) return false
    return true
  }

  @computed
  public get playStoreButtonShown() {
    if (this.rootStore.appStore.isIos) return false
    if (this.rootStore.appStore.isNative) return false
    return true
  }

  @computed
  public get spacerMdWidth() {
    // if (!this.playStoreButtonShown) return '5'
    // if (!this.appStoreButtonShown) return '5'
    return '0'
  }

  @computed
  public get bothButtonsShown() {
    if (!this.playStoreButtonShown) return false
    if (!this.appStoreButtonShown) return false
    return true
  }

  @computed
  public get buttonMdWidth() {
    if (!this.playStoreButtonShown) return '12'
    if (!this.appStoreButtonShown) return '12'
    return '6'
  }
}
