import agent from '../../Agent'
import { RootStore } from '../../stores/RootStore'
import { IBoardInvitationDTO } from '../dto/IBoardInvitationDTO'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'
import { action } from 'mobx'

export class BoardInvitationsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action
  public save(dto: IBoardInvitationDTO) {
    this.rootStore.boardsStore.currentBoard.addInvitation(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.BOARDINVITATION_SAVE, dto)
  }

  @action
  public async saveOnServer(dto: IBoardInvitationDTO) {
    const result: {
      IsSuccessful: boolean
      InvitationNotFound: boolean
      ToAppUserIdentityId: string
      BoardInvitation: IBoardInvitationDTO
      UserNotExists: boolean
    } = await agent.BoardInvitations.save(dto)
    this.rootStore.boardsStore.currentBoard.updateInvitation(dto)
    if (!result.IsSuccessful) {
      alert('Failed to invite ' + dto.ToEmailAddress + '. Please try again.')
      return
    }
  }
}
