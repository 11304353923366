import { observable, action, makeObservable } from 'mobx'
import { serialize, deserialize, serializable } from 'serializr'
import { IBoardInvitationDTO } from '../dto/IBoardInvitationDTO'
import { UUIDUtils } from '../../utils/UUIDUtils'

export class BoardInvitation implements IBoardInvitationDTO {
  public static create(boardGuid: string) {
    const inv = new BoardInvitation()
    inv.BoardGuid = boardGuid
    inv.BoardInvitationGuid = UUIDUtils.generateUUID()
    inv.StatusId = 1
    return inv
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public BoardInvitationId: number = 0
  @serializable @observable public BoardInvitationGuid: string = ''
  @serializable @observable public FromName: string = ''
  @serializable @observable public ToEmailAddress: string = ''
  @serializable @observable public FromAppUserId: number = 0
  @serializable @observable public BoardName: string = ''
  @serializable @observable public BoardGuid: string = ''
  @serializable @observable public IsDeleted: boolean = false
  @serializable @observable public StatusId: number = 0
  @serializable @observable public BoardId: number = 0
  public isOnServer: boolean = false

  @action
  public setToEmailAddress(val) {
    this.ToEmailAddress = val
  }

  public markIsOnServer(): void {
    this.isOnServer = true
  }

  public markIsNotOnServer(): void {
    this.isOnServer = false
  }

  public clone(): BoardInvitation {
    return deserialize(BoardInvitation, serialize(this))
  }

  public toDTO(): IBoardInvitationDTO {
    return serialize(this)
  }
}
