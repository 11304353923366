import React from 'react'
import { IonGrid, IonRow, IonCol, IonButton, IonIcon } from '@ionic/react'
import { Entypo } from 'react-web-vector-icons'
import { HelpFooterVM } from './HelpFooterVM'
import { useLocalObservable } from 'mobx-react'
import { chatboxOutline } from 'ionicons/icons'
import './HelpFooter.scss'

const HelpFooter: React.FC = () => {
  const vm = useLocalObservable(() => new HelpFooterVM())

  return (
    <IonGrid id='HelpFooter'>
      <IonRow>
        <IonCol sizeXs='12' sizeMd='6'>
          <IonButton className='visit-button' fill='clear' type='button' onClick={() => vm.goToWebsite()}>
            <Entypo size={21} name='browser' />
            &nbsp; groceryboard.io
          </IonButton>
        </IonCol>
        <IonCol sizeXs='12' sizeMd='6'>
          <IonButton className='trouble-button' fill='clear' type='button' onClick={() => vm.goToContactPage()}>
            <IonIcon icon={chatboxOutline} />
            &nbsp; Having Trouble?
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default HelpFooter
