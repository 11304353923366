import React, { useEffect } from 'react'
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonPage,
  IonSpinner,
  IonIcon,
  IonTitle,
  IonToast,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { match } from 'react-router'
import { AuthStore } from 'src/login/store/AuthStore'
import { UserStore } from '../user/store/UserStore'
import { ResetPasswordVM } from './ResetPasswordVM'
import { RootStore } from '../stores/RootStore'
import './ResetPasswordPage.scss'
import ErrorMessages from '../shared/error-messages/ErrorMessages'
import LogoHeader from '../shared/logo-header/LogoHeader'
import PanelCard from '../shared/panel/PanelCard'
import { arrowBack, checkmarkCircle, checkmarkOutline, eye, eyeOff, lockOpenOutline } from 'ionicons/icons'
import HelpFooter from '../shared/help-footer/HelpFooter'
import { TextUtils } from '../shared/TextUtils'

interface Props {
  appStore?: AppStore
  authStore?: AuthStore
  userStore?: UserStore
  history?: any
  match?: match
  rootStore?: RootStore
}

const ResetPasswordPage: React.FC<Props> = ({ appStore, authStore, rootStore, userStore, history, match }) => {
  const vm = useLocalObservable(() => new ResetPasswordVM(rootStore, match.params['token']))

  useEffect(() => {
    authStore.logout()
    appStore.setCurrentRoute('/reset-password')
    appStore.themeGenVM.loadTheme('system')
  }, [userStore, authStore, appStore, match.params])

  if (!vm) return <IonSpinner />

  const renderToast = () => {
    if (!vm.toastShown) return
    return (
      <IonToast
        cssClass='success-toast'
        color='success'
        isOpen={vm.toastShown}
        message='Password successfully updated!'
        position='top'
        duration={3000}
        onDidDismiss={() => vm.goToHomePage()}
        icon={checkmarkCircle}
      />
    )
  }

  const renderSpinner = () => {
    if (!vm.isProcessing) return
    if (vm.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  return (
    <IonPage id='ResetPasswordPage'>
      <IonContent scrollY ref={(e) => appStore.applyScrollStyles(e)}>
        <LogoHeader />
        <PanelCard maxWidth='600px'>
          <IonTitle>Enter your new password</IonTitle>
          <br />
          <form
            onSubmit={(e) => {
              e.preventDefault()
              vm.save()
            }}
            autoComplete='off'
          >
            <IonList>
              <IonItem>
                <IonLabel position='stacked'>New Password</IonLabel>
                <IonInput
                  type={vm.passwordShown ? 'text' : 'password'}
                  name='NewPassword'
                  autofocus={true}
                  autocomplete='new-password'
                  disabled={vm.inputDisabled}
                  value={vm.password}
                  onKeyDown={(e) => e.key === 'Enter' && vm.save()}
                  onIonChange={(e) => vm.setPassword(e.detail.value)}
                />
                <IonButton fill='clear' slot='end' onClick={() => vm.toggleShowPassword()} tabIndex={-1}>
                  <IonIcon slot='end' icon={vm.passwordShown ? eyeOff : eye} />
                </IonButton>
              </IonItem>
              <IonItem>
                <IonLabel position='stacked'>Confirm Password</IonLabel>
                <IonInput
                  type={vm.passwordShown ? 'text' : 'password'}
                  value={vm.passwordConfirm}
                  autocomplete='new-password'
                  disabled={vm.inputDisabled}
                  name='NewPasswordConfirm'
                  onKeyDown={(e) => e.key === 'Enter' && vm.save()}
                  onIonChange={(e) => vm.setPasswordConfirm(e.detail.value)}
                />
                <IonButton fill='clear' slot='end' onClick={() => vm.toggleShowPassword()} tabIndex={-1}>
                  <IonIcon slot='end' icon={vm.passwordShown ? eyeOff : eye} />
                </IonButton>
              </IonItem>
            </IonList>
            <IonButton expand='full' type='submit' disabled={vm.inputDisabled} color='medium'>
              Change Password
              <IonIcon icon={checkmarkOutline} slot='end' />
              &nbsp;
              {renderSpinner()}
            </IonButton>
          </form>
          <ErrorMessages messages={vm.errorMessages} />
          <IonGrid id='OtherButtons'>
            <IonRow>
              <IonCol sizeXs='12' sizeMd='6'>
                <IonButton fill='clear' type='button' className='back-button' onClick={() => vm.goToLogin()}>
                  Back to Sign In
                  <IonIcon icon={arrowBack} slot='start' />
                </IonButton>
              </IonCol>
              <IonCol sizeXs='12' sizeMd='6'>
                <IonButton fill='clear' type='button' className='back-button' onClick={() => vm.goToForgotPassword()}>
                  Get another code
                  <IonIcon icon={lockOpenOutline} slot='start' />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          <HelpFooter />
          {renderToast()}
        </PanelCard>
      </IonContent>
    </IonPage>
  )
}

export default inject('appStore', 'rootStore', 'userStore', 'authStore')(observer(ResetPasswordPage))
