import { observable, action, makeObservable } from 'mobx'
import { BoardUser } from './BoardUser'
import { BoardInvitation } from './BoardInvitation'
import { IBoardDTO } from '../dtos/IBoardDTO'
import { UUIDUtils } from '../../utils/UUIDUtils'
import { deserialize, list, object, serializable, serialize } from 'serializr'
import { IBoardInvitationDTO } from '../../board-invitations/dto/IBoardInvitationDTO'

export class Board implements IBoardDTO {
  static create() {
    const brd = new Board()
    brd.BoardGuid = UUIDUtils.generateUUID()
    return brd
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public BoardId: number = 0
  @serializable @observable public BoardGuid: string = ''
  @serializable @observable public Name: string = ''
  @serializable @observable public InvitationCode: string = null
  @serializable(list(object(BoardUser))) @observable public BoardUsers: BoardUser[] = []
  @serializable(list(object(BoardInvitation))) @observable public BoardInvitations: BoardInvitation[] = []
  @serializable @observable public IsSeeded: boolean = false
  @serializable @observable public IsDeleted: boolean = false
  public isOnServer: boolean = false

  public markIsOnServer(): void {
    this.isOnServer = true
  }

  public markIsNotOnServer(): void {
    this.isOnServer = false
  }

  @action
  public setName(val) {
    this.Name = val
  }

  @action
  public markAsDeleted() {
    this.IsDeleted = true
  }

  @action
  public addInvitation(dto: IBoardInvitationDTO) {
    this.BoardInvitations.push(deserialize(BoardInvitation, dto))
  }

  @action
  public updateInvitation(dto: IBoardInvitationDTO) {
    const invIdx = this.BoardInvitations.findIndex((e) => e.BoardInvitationGuid === dto.BoardInvitationGuid)
    if (invIdx === -1) {
      this.addInvitation(dto)
      return
    }
    this.BoardInvitations[invIdx] = deserialize(BoardInvitation, dto)
  }

  public clone(): Board {
    return deserialize(Board, serialize(this))
  }

  public toDTO(): IBoardDTO {
    return serialize(this)
  }
}
