import { RootStore } from '../../../stores/RootStore'
import { computed, action, observable, makeObservable, runInAction } from 'mobx'
import { RecipeEditVM } from './RecipeEditVM'
import { RecipeItem } from '../../aggregate/RecipeItem'
import { QuantityTypeVM } from './QuantityTypeVM'
import { QuantityTypesService } from '../../../quantities/service/QuantyTypesService'
import { ItemsSelectListVM } from '../../../items-select/view-models/ItemsSelectListVM'
import { IItemSelectableVM } from '../../../items-select/interfaces/IItemSelectableVM'
import { Item } from '../../../items/aggregate/Item'
import { getLines } from '../../../utils/GetLines'
import { IRecipeJobResult } from '../../interfaces/IRecipeItemJobResult'
import { IItemDTO } from '../../../items/dtos/IItemDTO'
import capitalizeWords from '../../../utils/CaptilizeWords'
import { UUIDUtils } from '../../../utils/UUIDUtils'
import { ItemsService } from '../../../items/services/ItemsService'
import { ItemNewVM } from '../../../items/view-models/new/ItemNewVM'
import { ItemEditModalVM } from '../../../items/view-models/edit/ItemEditModalVM'
import { ParseIngredientResponse } from '../../interfaces/ParseIngredientResponse'

export class RecipeItemRowVM implements IItemSelectableVM {
  private rootStore: RootStore
  private recipeItem: RecipeItem
  private parentVM: RecipeEditVM
  private textbox: HTMLIonTextareaElement = null
  @observable private nativeTextbox: HTMLTextAreaElement
  @observable private parseResult: ParseIngredientResponse
  @observable private suggestedCategoryGuid: string = undefined
  private to: NodeJS.Timer
  private shouldCombineWithPreviousRow: boolean = false
  private matchDependingDescription: string = undefined
  @observable private lastClearedItemGuid: string = undefined
  @observable private suggestedCategoryResult: IRecipeJobResult = undefined
  private hasInteracted: boolean = false

  constructor(rootStore: RootStore, editVM: RecipeEditVM, recipeItem: RecipeItem) {
    makeObservable(this)
    this.rootStore = rootStore
    this.recipeItem = recipeItem
    this.parentVM = editVM
    this.loadQuantityOptions()
  }

  public reloadRows() {} //for contract

  @observable public quantityOptions: Array<QuantityTypeVM> = []
  @observable public modalShown: boolean = false
  @observable public itemsList: ItemsSelectListVM = null
  public type: string = 'Ingredient'
  @observable public itemNewVM: ItemNewVM = null
  @observable public itemEditVM: ItemEditModalVM = null

  private loadQuantityOptions() {
    const svc = new QuantityTypesService()
    this.quantityOptions = svc
      .getQuantityTypes()
      .map((e) => {
        const vm = new QuantityTypeVM(this, e)
        vm.setValue(this.quantity)
        return vm
      })
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
  }

  @computed
  public get recipeItemGuid(): string {
    return this.recipeItem.RecipeItemGuid
  }

  @computed
  public get note(): string {
    return this.recipeItem.Note
  }

  @computed
  public get description(): string {
    return this.recipeItem.Description
  }

  @computed
  public get viewLinkedItem(): boolean {
    return this.parentVM.viewLinkedItems
  }

  @computed
  public get quantityTypeAbbr(): string {
    return this.recipeItem.QuantityTypeAbbr
  }

  @computed
  public get selectedQuantityType(): QuantityTypeVM {
    return this.quantityOptions.find((e) => e.isSelected)
  }

  @computed
  public get selectedQuantityTypeName(): string {
    if (!this.selectedQuantityType) return ''
    return this.selectedQuantityType.name
  }

  @computed
  public get editModalShown(): boolean {
    return this.parentVM.editingRecipeItemGuid !== null
  }

  @action
  public setDescription(val) {
    if (this.editModalShown) {
      this.recipeItem.setDescription(val)
      if (this.nativeTextbox) this.nativeTextbox.value = val
    } else {
      this.parseLines(val)
    }
    if (!this.hasItem) this.dispatchParseRecipeItem()
    if (this.matchDependingDescription && this.description !== this.matchDependingDescription) this.clearItem()
  }

  @action
  public parseLines(val) {
    let lines = getLines(val)
    if (lines.length > 1 && lines[0] === '') lines = [lines.join('')]
    const thisVal = lines[0]
    this.recipeItem.setDescription(thisVal)
    if (this.nativeTextbox) this.nativeTextbox.value = thisVal
    if (lines.length === 1) return
    lines.splice(0, 1)
    lines = lines.reverse()
    lines.forEach((e, idx) => {
      let doAdd = false
      if (e.trim() !== '') doAdd = true
      if (idx === lines.length - 1 && e === '') doAdd = true
      if (thisVal === '') return
      if (this.nextRow && !this.nextRow.hasDescription && idx > 0) {
        this.nextRow.setDescription(e)
        this.nextRow.setFocus()
        return
      }
      if (!doAdd) return
      this.parentVM.addRecipeItem(e, this.rank + 1)
      setTimeout(() => this.nextRow.setFocus(), 1)
    })
  }

  @action
  public markShouldCombineWithPrevious() {
    this.shouldCombineWithPreviousRow = true
  }

  @action
  public combineWithPreviousRow() {
    if (!this.shouldCombineWithPreviousRow) return false
    this.previousRow.setDescription(this.previousRow.description + this.description)
    this.delete()
    return true
  }

  @action
  public clearItem() {
    if (!this.editable) return
    if (!this.itemGuid) return
    this.hasInteracted = true
    this.lastClearedItemGuid = this.itemGuid
    // const hasCustomDescription = this.description !== '1 ' + this.itemName
    this.recipeItem.clearItem()
    this.clearSuggestedCategory()
    this.dispatchParseRecipeItem()
    // if (!hasCustomDescription) this.setDescription('')
  }

  @action
  public openItemSelect() {
    this.itemsList = new ItemsSelectListVM(this.rootStore, this, false, false)
    this.itemsList.show()
  }

  @computed
  public get rank(): number {
    return this.recipeItem.Rank
  }

  @action
  public setQuantity(val) {
    if (!val) val = 1
    val = val.toString()
    val = val.replace(/[^\d.]/g, '')
    this.recipeItem.setQuantity(val)
  }

  @action
  public setQuantityType(val) {
    this.recipeItem.setQuantityType(val)
  }

  @action
  public setRank(val) {
    this.recipeItem.setRank(val)
  }

  @action
  public setNote(val) {
    this.recipeItem.setNote(val)
  }

  @computed
  public get hasItem(): boolean {
    return this.recipeItem.hasItem
  }

  @computed
  public get hasSuggestedItems(): boolean {
    return Boolean(this.suggestedItems.length)
  }

  @computed
  public get hasSuggestedItemsResult(): boolean {
    return Boolean(this.parseResult)
  }

  @computed
  public get suggestedItems(): IItemDTO[] {
    if (!this.parseResult) return []
    const items = []
    if (this.parseResult.highConfidenceMatch) {
      const foundItem = this.rootStore.itemsStore.get(this.parseResult.highConfidenceMatch.itemId)
      if (foundItem) {
        items.push({
          ItemGuid: this.parseResult.highConfidenceMatch.itemId,
          Name: this.parseResult.highConfidenceMatch.itemName,
          CategoryGuid: this.parseResult.highConfidenceMatch.itemName,
        } as IItemDTO)
      }
    }
    if (this.parseResult.matches) {
      this.parseResult.matches.forEach((e) => {
        items.push({
          ItemGuid: e.itemId,
          Name: e.itemName,
          CategoryGuid: e.itemName,
        } as IItemDTO)
      })
    }
    return items
  }

  @computed
  public get hasSuggestedNewItem(): boolean {
    return Boolean(this.parseResult?.suggestedNewItem)
  }

  @computed
  public get fullSuggestedNewItemName(): string {
    if (!this.hasSuggestedNewItem) return ''
    const foundCategory = this.rootStore.categoriesStore.get(this.suggestedCategoryGuid)
    if (!foundCategory) return ''
    return foundCategory.Name + ': ' + this.suggestedNewItemName
  }

  @computed
  public get suggestedNewItemCategoryName(): string {
    if (!this.hasSuggestedNewItem) return ''
    const foundCategory = this.rootStore.categoriesStore.get(this.suggestedCategoryGuid)
    if (!foundCategory) return ''
    return foundCategory.Name
  }

  @computed
  public get suggestedNewItemName(): string {
    if (!this.hasSuggestedNewItem) return ''
    if (!this.parseResult) return ''
    if (!this.parseResult.suggestedNewItem) return ''
    return capitalizeWords(this.parseResult.suggestedNewItem.itemName)
  }

  @action
  public acceptSuggestedNewItem() {
    const itemDto = {
      ItemGuid: UUIDUtils.generateUUID(),
      Name: this.suggestedNewItemName,
      BoardId: this.rootStore.boardsStore.currentBoardId,
      CategoryGuid: this.suggestedCategoryGuid,
    } as IItemDTO
    const svc = new ItemsService(this.rootStore)
    svc.saveItem(itemDto)
    this.setItem(itemDto.ItemGuid)
    // this.parseResult.highConfidenceMatch = {
    //   itemId: itemDto.ItemGuid,
    //   itemName: itemDto.Name,
    //   categoryId: itemDto.CategoryGuid,
    //   categoryName: this.suggestedNewItemCategoryName,
    // }
    this.clearSuggestedCategory()
  }

  @action
  public editSuggestedNewItem() {
    this.itemNewVM = new ItemNewVM(this.rootStore, {
      newItemTypeName: this.parentVM.type,
      backdropShown: true,
      addToShown: false,
      defaultCategoryGuid: this.suggestedCategoryGuid,
      defaultName: this.suggestedNewItemName,
      onCompleted: (newName, itemGuid, doAdd, qty) => {
        this.setItem(itemGuid)
      },
    })
    this.itemNewVM.show(this.suggestedNewItemName)
  }

  @action
  public editItem() {
    this.itemEditVM = new ItemEditModalVM(this.rootStore, this.itemGuid, {
      backdropShown: true,
      onCompleted: (qty: number) => {},
    })
    this.itemEditVM.show()
  }

  @computed
  public get isEditingItem(): boolean {
    return Boolean(this.itemEditVM)
  }

  @action
  public hideItemEditModal() {
    this.itemEditVM.hide()
    this.itemEditVM = null
  }

  @action
  private clearParseResult() {
    this.parseResult = undefined
    this.clearSuggestedCategory()
  }

  @computed
  public get hasDescription(): boolean {
    return this.recipeItem.Description !== ''
  }

  @computed
  private get item(): Item {
    const foundItem = this.rootStore.itemsStore.get(this.recipeItem.ItemGuid)
    return foundItem
  }

  @computed
  public get itemName(): string {
    const foundItem = this.rootStore.itemsStore.get(this.recipeItem.ItemGuid)
    if (!foundItem) return ''
    return foundItem.Name
  }

  @computed
  public get categoryName(): string {
    const foundItem = this.rootStore.itemsStore.get(this.recipeItem.ItemGuid)
    if (!foundItem) return ''
    const foundCategory = this.rootStore.categoriesStore.get(foundItem.CategoryGuid)
    if (!foundCategory) return ''
    return foundCategory.Name
  }

  @computed
  public get fullItemName(): string {
    const foundItem = this.rootStore.itemsStore.get(this.recipeItem.ItemGuid)
    if (!foundItem) return ''
    const foundCategory = this.rootStore.categoriesStore.get(foundItem.CategoryGuid)
    if (!foundCategory) return ''
    return foundCategory.Name + ': ' + foundItem.Name
  }

  public getFullSuggestedItemName(item: IItemDTO): string {
    const foundCategory = this.rootStore.categoriesStore.get(item.CategoryGuid)
    if (!foundCategory) return ''
    return foundCategory.Name + ': ' + item.Name
  }

  public getSuggestedItemCategoryName(item: IItemDTO): string {
    const foundCategory = this.rootStore.categoriesStore.get(item.CategoryGuid)
    if (!foundCategory) return ''
    return foundCategory.Name
  }

  @computed
  public get itemGuid(): string {
    return this.recipeItem.ItemGuid
  }

  @computed
  public get quantity(): number {
    return this.recipeItem.Quantity
  }

  @computed
  public get key(): string {
    return this.recipeItem.RecipeItemGuid
  }

  @action
  public delete() {
    this.parentVM.deleteRecipeItem(this.recipeItem.RecipeItemGuid)
  }

  @computed
  public get isDeleted(): boolean {
    return this.recipeItem.IsDeleted
  }

  @action
  public setItem(itemGuid: string, dependingDescription: string = undefined) {
    this.recipeItem.setItem(itemGuid)
    this.matchDependingDescription = dependingDescription
  }

  @action
  private setSuggestedCategory(val: string) {
    this.suggestedCategoryGuid = val
  }

  @action
  private clearSuggestedCategory() {
    this.suggestedCategoryResult = undefined
    this.suggestedCategoryGuid = undefined
  }

  @action
  public acceptSuggestedItem(itemGuid: string) {
    this.setItem(itemGuid)
  }

  @action
  public increaseQuantity(itemGuid: string) {
    this.recipeItem.setItem(itemGuid)
    this.itemsList.done()
    this.itemsList = undefined
    if (this.recipeItem.hasDescription) return
    const item = this.rootStore.itemsStore.get(itemGuid)
    if (!item) return
    this.recipeItem.setDescription(item.Name)
  }

  @action
  public decreaseQuantity() {
    this.recipeItem.decreaseQuantity()
    this.quantityOptions.forEach((e) => e.setValue(this.quantity))
  }

  @action
  public showEditModal() {
    this.setDescription(this.description)
    this.parentVM.setEditingRecipeItem(this.recipeItemGuid)
  }

  @computed
  public get previousRow() {
    return this.parentVM.recipeItems.find((e) => e.rank === this.rank - 1)
  }

  @computed
  public get nextRow() {
    return this.parentVM.recipeItems.find((e) => e.rank === this.rank + 1)
  }

  @computed
  public get cursorAtStart(): boolean {
    return this.getCursorPosition() === 0
  }

  public getCursorPosition(): number {
    if (!this.nativeTextbox) return -1
    return this.nativeTextbox.selectionStart
  }

  @computed
  public get cursorAtEnd(): boolean {
    return this.getCursorPosition() === this.description.length
  }

  @action
  public moveCursorToEnd() {
    if (!this.nativeTextbox) return
    this.nativeTextbox.selectionStart = this.description.length
    this.nativeTextbox.selectionEnd = this.description.length
  }

  @action
  public moveCursorTo(pos: number) {
    if (!this.nativeTextbox) return
    this.nativeTextbox.selectionStart = pos
    this.nativeTextbox.selectionEnd = pos
  }

  @action
  public moveCursorToStart() {
    if (!this.nativeTextbox) return
    this.nativeTextbox.selectionStart = 0
    this.nativeTextbox.selectionEnd = 0
  }

  @action
  public async setFocus() {
    if (!this.textbox) {
      console.log('retry 1')
      setTimeout(() => this.setFocus(), 100)
      return
    }
    if (!this.nativeTextbox) {
      console.log('retry 2')
      setTimeout(() => this.setFocus(), 100)
      return
    }
    console.log('focused')
    await this.textbox.setFocus()
    await this.textbox.setFocus()
  }

  @action
  public hideModal() {
    if (this.isEmpty) this.parentVM.deleteRecipeItem(this.recipeItemGuid)
    this.parentVM.clearEditingRecipeItem()
    console.log('hide modal called')
  }

  @computed
  public get isEmpty(): boolean {
    if (this.description && this.description !== '') return false
    if (this.itemGuid) return false
    return true
  }

  @action
  public save() {
    this.hideModal()
    if (this.parentVM.autoSave) this.parentVM.save()
    console.log('save called')
  }

  @action
  public cancel() {
    this.hideModal()
  }

  @computed
  public get isValid() {
    return false
  }

  @computed
  public get modalTitle() {
    return 'Edit Ingredient'
  }

  public removeQuantity(itemGuid: string) {
    throw new Error('Method not implemented.')
  }

  public getItemQuantity(itemGuid: string): number {
    return this.itemGuid === itemGuid ? 1 : 0
  }

  public getIsGotten(itemGuid: string): boolean {
    return false
  }

  public toggleGotten(itemGuid: string) {
    throw new Error('Method not implemented.')
  }

  public markAsCleared(itemGuid: string) {
    throw new Error('Method not implemented.')
  }

  public doneAddingItems() {
    // throw new Error('Method not implemented.')
  }

  public async setTextbox(e: any) {
    this.textbox = e
    this.nativeTextbox = undefined
    if (!e) return
    this.nativeTextbox = await this.textbox.getInputElement()
    if (!this.nativeTextbox) {
      setTimeout(() => this.setTextbox(e), 10)
      return
    }
  }

  @computed
  private get categoryGuid(): string {
    return this.item.CategoryGuid
  }

  @computed
  public get categoryColor(): string {
    if (!this.rootStore.categoriesStore) return ''
    const foundCat = this.rootStore.categoriesStore.get(this.categoryGuid)
    if (!foundCat) return ''
    return foundCat.Color
  }

  public dispatchParseRecipeItem() {
    clearTimeout(this.to)
    if (this.parseResult?.Description !== this.description) this.clearParseResult()
    if (window.Offline.state === 'down') return
    this.to = setTimeout(() => this.parseRecipeItem(), 1500)
  }

  @action
  private async parseRecipeItem() {
    if (this.hasItem) return
    if (this.parseResult?.Description !== this.description) this.clearParseResult()
    await this.parentVM.svc.parseRecipeItem(this.recipeItem.toDTO())
  }

  public get hasResultMatchedItem(): boolean {
    if (!Boolean(this.parseResult?.highConfidenceMatch)) return false
    if (!this.parseResult.highConfidenceMatch.itemId) return false
    const itemId = this.parseResult.highConfidenceMatch.itemId
    const item = this.rootStore.itemsStore.get(itemId)
    if (!item) return false
    return true
  }

  @action
  public updateParseResult(result: ParseIngredientResponse) {
    if (result.Description !== this.description) {
      console.log('description mismatch for', this.description, result.Description)
      return
    }
    console.log('updating parse result', result)
    this.maybeTakeMatchedItem(result)
    this.parseResult = result
    if (this.parseResult.suggestedNewItem) {
      this.setSuggestedCategory(this.parseResult.suggestedNewItem.categoryId)
    }
  }

  @computed
  public get spinnerShown(): boolean {
    if (this.hasItem) return false
    if (this.hasResultMatchedItem) return false
    if (!this.hasDescription) return false
    if (this.hasSuggestedItemsResult) return false
    if (this.hasResultError) return false
    return true
  }

  @computed
  public get hasResultError(): boolean {
    if (!this.parseResult) return false
    if (!this.parseResult.matches) return true
    return false
  }

  @computed
  public get selectItemPrompt(): string {
    if (!this.hasDescription) return 'Select item'
    if (this.hasResultError) return 'Select item'
    return 'Select other item'
  }

  private maybeTakeMatchedItem(result: ParseIngredientResponse) {
    if (this.hasInteracted) return
    if (!result.highConfidenceMatch) return
    if (!result.highConfidenceMatch.itemId) return
    if (result.highConfidenceMatch.itemId === this.lastClearedItemGuid) return
    const itemId = result.highConfidenceMatch.itemId
    const item = this.rootStore.itemsStore.get(itemId)
    if (!item) return
    this.setItem(result.highConfidenceMatch.itemId, this.description)
  }

  @action
  public updateSuggestedCategoryResult(result: IRecipeJobResult) {
    this.suggestedCategoryResult = result
    if (result.Description !== this.description) return
    this.setSuggestedCategory(result.SuggestedCategoryGuid)
  }

  @computed
  public get editable() {
    return !this.parentVM.bulkEditMode
  }

  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }
}
