import { observable, action, computed, makeObservable, runInAction } from 'mobx'
import agent from '../Agent'
import { RootStore } from '../stores/RootStore'

export class ResetPasswordVM {
  private rootStore: RootStore
  private authToken: string = undefined

  constructor(rootStore: RootStore, token: string = '') {
    makeObservable(this)
    this.rootStore = rootStore
    this.token = token
  }

  @observable public token: string = ''
  @observable public password: string = ''
  @observable public passwordConfirm: string = ''
  @observable public errorMessages: Array<string> = []
  @observable public passwordShown: boolean = false
  @observable public isProcessing: boolean = false
  @observable public toastShown: boolean = false

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  @action
  public setPassword(password: string): void {
    this.password = password
    this.clearErrorMessages()
  }

  @action
  public setPasswordConfirm(password: string): void {
    this.passwordConfirm = password
    this.clearErrorMessages()
  }

  @action
  public toggleShowPassword(): void {
    this.passwordShown = !this.passwordShown
  }

  public goToLogin() {
    this.rootStore.appStore.navigateTo('/login')
  }

  public goToForgotPassword() {
    this.rootStore.appStore.navigateTo('/forgot-password')
  }

  @action
  private showToast() {
    this.toastShown = true
  }

  @computed
  public get isValid() {
    if (this.password === '') return false
    if (this.passwordConfirm === '') return false
    return true
  }

  @action
  public clearErrorMessages() {
    this.errorMessages = []
  }

  @computed
  public get inputDisabled() {
    return this.isProcessing || this.toastShown
  }

  @action
  public async save() {
    this.clearErrorMessages()
    const isSame = this.password === this.passwordConfirm
    if (!isSame) {
      this.errorMessages.push('Passwords do not match')
      return false
    }
    this.isProcessing = true
    const form = {
      Token: this.token,
      Password: this.password,
    }
    let results
    try {
      results = await agent.Users.resetPassword(form)
    } catch (e) {
      this.setErrorMessages([e.message])
      console.error(e)
      this.markAsNotProcessing()
      return
    }
    if (!results) {
      this.setErrorMessages(['Unknown error'])
      this.markAsNotProcessing()
      return
    }
    if (!Array.isArray(results)) {
      this.setErrorMessages(['Unknown error'])
      this.markAsNotProcessing()
      return
    }
    if (results[0] !== 'Success') {
      this.setErrorMessages(results)
      this.markAsNotProcessing()
      return
    }
    if (results[0] === 'Success' && results.length === 2) {
      this.authToken = results[1]
    }
    this.showToast()
  }

  @action
  private markAsNotProcessing() {
    this.isProcessing = false
  }

  @action
  private setErrorMessages(vals: string[]) {
    vals.forEach((e) => this.errorMessages.push(e))
  }

  public goToHomePage() {
    this.rootStore.appStore.setToken(this.authToken)
    this.rootStore.appStore.navigateTo('/')
  }
}
