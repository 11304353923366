import React from 'react'
import {
  IonModal,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonButtons,
  IonIcon,
  IonToast,
} from '@ionic/react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { arrowBack, checkmark, checkmarkCircle, eye, eyeOff } from 'ionicons/icons'
import { RootStore } from '../../../stores/RootStore'
import { RegisterModalVM } from '../../view-models/guest-user/RegisterModalVM'
import ErrorMessages from '../../../shared/error-messages/ErrorMessages'

interface Props {
  rootStore?: RootStore
  onClose: (success: boolean) => void
}

const RegisterModal: React.FC<Props> = ({ rootStore, onClose }) => {
  const vm = useLocalObservable(() => new RegisterModalVM(rootStore, onClose))

  const renderButtons = () => {
    if (vm.isProcessing) return
    return (
      <div id='buttons'>
        <IonButton
          disabled={vm.isProcessing || vm.toastShown}
          type='button'
          size='small'
          color='secondary'
          onClick={() => vm.closeModal()}
        >
          Cancel
        </IonButton>
        <IonButton tabIndex={1} type='submit' size='small' color='tertiary' disabled={vm.isProcessing || vm.toastShown}>
          Register
        </IonButton>
      </div>
    )
  }

  const renderToast = () => {
    return (
      <IonToast
        cssClass='success-toast'
        color='success'
        isOpen={vm.toastShown}
        message='You have been successfully registered!'
        position='bottom'
        duration={3000}
        icon={checkmarkCircle}
      />
    )
  }

  const renderNameInput = () => {
    return (
      <IonItem lines='inset'>
        <IonLabel position='stacked'>Name</IonLabel>
        <IonInput
          ref={(e) => {
            vm.setFocus(e)
            vm.setTabIndex(e, 1)
          }}
          type='text'
          name='name'
          value={vm.name}
          onIonChange={(e) => vm.setName(e.detail.value!)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') vm.handleSave()
          }}
        />
      </IonItem>
    )
  }

  const renderEmailInput = () => {
    return (
      <IonItem lines='inset'>
        <IonLabel position='stacked'>Email</IonLabel>
        <IonInput
          ref={(e) => {
            vm.setFocus(e)
            vm.setTabIndex(e, 1)
          }}
          type='email'
          name='email'
          value={vm.email}
          onIonChange={(e) => vm.setEmail(e.detail.value!)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') vm.handleSave()
          }}
        />
      </IonItem>
    )
  }

  const renderPasswordInput = () => {
    return (
      <IonItem lines='inset'>
        <IonLabel position='stacked'>Password</IonLabel>
        <IonInput
          ref={(e) => {
            vm.setFocus(e)
            vm.setTabIndex(e, 1)
          }}
          type={vm.passwordShown ? 'text' : 'password'}
          value={vm.newPassword}
          autocomplete='new-password'
          onIonChange={(e) => vm.setNewPassword(e.detail.value!)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') vm.handleSave()
          }}
        />
        <IonButton fill='clear' slot='end' onClick={() => vm.toggleShowPassword()} tabIndex={-1}>
          <IonIcon slot='end' icon={vm.passwordShown ? eyeOff : eye} />
        </IonButton>
      </IonItem>
    )
  }

  const renderConfirmPasswordInput = () => {
    return (
      <IonItem lines='inset'>
        <IonLabel position='stacked'>Confirm Password</IonLabel>
        <IonInput
          ref={(e) => vm.setTabIndex(e, 1)}
          type={vm.passwordShown ? 'text' : 'password'}
          autocomplete='new-password'
          value={vm.confirmPassword}
          onIonChange={(e) => vm.setConfirmPassword(e.detail.value!)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') vm.handleSave()
          }}
        />
        <IonButton fill='clear' slot='end' onClick={() => vm.toggleShowPassword()} tabIndex={-1}>
          <IonIcon slot='end' icon={vm.passwordShown ? eyeOff : eye} />
        </IonButton>
      </IonItem>
    )
  }

  return (
    <IonModal isOpen onDidDismiss={() => vm.closeModal()} id='RegisterModal'>
      <IonHeader>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={() => vm.closeModal()} tabIndex={-1}>
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Register by Email</IonTitle>
          <IonButtons slot='end'>
            <IonButton
              type='submit'
              onClick={() => vm.handleSave()}
              tabIndex={-1}
              disabled={vm.isProcessing || vm.toastShown}
            >
              <IonIcon icon={checkmark} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form
          onSubmit={(e) => {
            vm.handleSave()
            e.preventDefault()
          }}
        >
          <IonList>
            {renderNameInput()}
            {renderEmailInput()}
            {renderPasswordInput()}
            {renderConfirmPasswordInput()}
          </IonList>
          <ErrorMessages messages={vm.errorMessages} />
          {renderButtons()}
          {renderToast()}
        </form>
      </IonContent>
    </IonModal>
  )
}

export default inject('rootStore')(observer(RegisterModal))
