import { observable, action, makeObservable } from 'mobx'
import { UUIDUtils } from '../../utils/UUIDUtils'
import { serializable } from 'serializr'
import { IAttachmentDTO } from '../dto/IAttachmentDTO'

export class Attachment implements IAttachmentDTO {
  public static create(storageFileGuid: string, rank: number) {
    const att = new Attachment()
    att.AttachmentGuid = UUIDUtils.generateUUID()
    att.StorageFileGuid = storageFileGuid
    att.Rank = rank
    return att
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public AttachmentGuid: string = undefined
  @serializable @observable public StorageFileGuid: string = undefined
  @serializable @observable public Rank: number = undefined
  @serializable @observable public IsDeleted: boolean = false

  @action
  public setRank(idx: number): void {
    this.Rank = idx
  }

  @action
  public markAsDeleted() {
    this.IsDeleted = true
  }
}
