import { inject, observer, useLocalObservable } from 'mobx-react'
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonSpinner,
  IonIcon,
  IonTitle,
  IonText,
  IonToast,
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react'
import React from 'react'
import PanelCard from '../shared/panel/PanelCard'
import {
  arrowBack,
  arrowForward,
  arrowForwardCircleOutline,
  checkmarkCircle,
  code,
  lockClosed,
  lockOpen,
  lockOpenOutline,
  send,
  sendOutline,
} from 'ionicons/icons'
import ErrorMessages from '../shared/error-messages/ErrorMessages'
import { ForgotPasswordVM } from './ForgotPasswordVM'
import HelpFooter from '../shared/help-footer/HelpFooter'

interface Props {
  vm: ForgotPasswordVM
}

const ForgotPasswordForm: React.FC<Props> = ({ vm }) => {
  const renderSpinner = () => {
    if (!vm.isProcessing) return
    if (vm.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  const renderSendButton = () => {
    return (
      <IonButton expand='full' color='medium' type='submit' id='ResetPasswordButton' disabled={vm.sendButtonDisabled}>
        Email Recovery Code
        <IonIcon icon={sendOutline} slot='end' />
        &nbsp;
        {renderSpinner()}
      </IonButton>
    )
  }

  const renderBackButton = () => {
    return (
      <IonButton className='back-button' fill='clear' type='button' id='BackButton' onClick={() => vm.goBack()}>
        <IonIcon icon={arrowBack} slot='start' />
        Back to Sign In
      </IonButton>
    )
  }

  const renderHaveCodeButton = () => {
    return (
      <IonButton
        className='back-button'
        fill='clear'
        type='button'
        id='HaveCodeButton'
        onClick={() => vm.goToRecoverAccount()}
      >
        <IonIcon icon={lockOpenOutline} slot='start' />
        Already have a code?
      </IonButton>
    )
  }

  const renderToast = () => {
    if (!vm.toastShown) return
    return (
      <IonToast
        cssClass='success-toast'
        color='success'
        isOpen={vm.toastShown}
        message='Recovery email successfully sent...'
        position='top'
        duration={3000}
        onDidDismiss={() => vm.goToRecoverAccount()}
        icon={checkmarkCircle}
      />
    )
  }

  return (
    <PanelCard maxWidth='600px'>
      <IonText className='title'>Forgot Password?</IonText>
      <IonText>
        <p>No worries. We've got your covered.</p>
        <p>
          We'll send you an email with an <strong>account recovery code</strong> that you can use to reset your
          password.
        </p>
        <p>Be sure to check your spam folder.</p>
      </IonText>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          vm.save()
        }}
      >
        <IonList>
          <IonItem>
            <IonLabel position='stacked'>Email</IonLabel>
            <IonInput
              disabled={vm.isProcessing || vm.toastShown}
              autofocus={true}
              onKeyPress={(e) => e.keyCode === 13 && vm.save()}
              type='text'
              id='email2'
              value={vm.email}
              onIonChange={(e) => vm.setEmail(e.target.value)}
            />
          </IonItem>
        </IonList>
        {renderSendButton()}
      </form>
      {renderToast()}
      <ErrorMessages messages={vm.errorMessages} />
      <IonGrid id='OtherButtons'>
        <IonRow>
          <IonCol sizeXs='12' sizeMd='6'>
            {renderBackButton()}
          </IonCol>
          <IonCol sizeXs='12' sizeMd='6'>
            {renderHaveCodeButton()}
          </IonCol>
        </IonRow>
      </IonGrid>
      <HelpFooter />
    </PanelCard>
  )
}

export default inject('rootStore')(observer(ForgotPasswordForm))
