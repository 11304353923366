import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonAlert,
  IonFab,
  IonFabButton,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { close, trashOutline, checkmarkSharp } from 'ionicons/icons'
import { RootStore } from 'src/stores/RootStore'
import { RecipesStore } from '../../store/RecipesStore'
import StepsList from './StepsList'
import CategoriesList from './CategoriesList'
import RecipeItemsList from './RecipeItemsList'
import DetailsForm from './DetailsForm'
import TabButtons from './TabButtons'
import RecipeCategoryNewModal from './RecipeCategoryNewModal'
import RecipeItemEditModal from './RecipeItemEditModal'
import BackIcon from 'src/shared/BackIcon'

interface Props {
  recipesStore: RecipesStore
  appStore: AppStore
  rootStore: RootStore
  history: any
  match: any
}

@inject('recipesStore')
@inject('appStore')
@inject('rootStore')
@observer
export default class RecipeEditPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const params = new URL(location.href).searchParams
    const name = params.get('name')
    this.props.appStore.setCurrentRoute('/recipeedit/' + this.props.match.params['id'])
    this.props.recipesStore.lazyLoadEditVM(this.props.match.params['id'], unescape(name))
  }

  componentWillUnmount() {
    this.props.recipesStore.lazyLoadEditVM('empty')
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  renderDeleteConfirm() {
    const { editVM } = this.props.recipesStore
    if (!editVM) return null
    return (
      <IonAlert
        isOpen={editVM.deleteConfirmShown}
        onDidDismiss={() => editVM.hideDeleteConfirm()}
        header={'Delete Recipe'}
        message={'Are you sure you wish to delete ' + editVM.name}
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'primary',
            handler: () => editVM.hideDeleteConfirm(),
          },
          {
            text: 'Yes',
            handler: () => editVM.delete(),
          },
        ]}
      />
    )
  }

  render() {
    const { editVM } = this.props.recipesStore
    if (!editVM) return null

    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='RecipeEditPage_Header' unselectable='on'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => editVM.back()}>
                <BackIcon />
              </IonButton>
            </IonButtons>
            <IonTitle>{editVM && editVM.pageTitle}</IonTitle>
            <IonButtons slot='end'>
              {editVM && editVM.isNewRecipe && (
                <IonButton onClick={() => editVM.back()}>
                  <IonIcon icon={close} />
                </IonButton>
              )}
              {editVM && !editVM.isNewRecipe && (
                <IonButton onClick={() => editVM.showDeleteConfirm()}>
                  <IonIcon icon={trashOutline} />
                </IonButton>
              )}
              <IonButton onClick={() => editVM.save()} disabled={!editVM.isValid}>
                <IonIcon icon={checkmarkSharp} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <TabButtons />
        </IonHeader>

        <IonContent
          id='RecipeEditPage_Content'
          ref={(e) => {
            editVM.setContentRef(e)
            this.props.appStore.applyScrollStyles(e)
          }}
          scrollY
          unselectable='on'
          style={{ height: this.props.appStore.listHeightPx }}
        >
          <DetailsForm />
          <CategoriesList />
          <StepsList />
          <RecipeItemsList />
          <RecipeCategoryNewModal />
          <br />
          <br />
          <br />
          <br />
          {this.renderDeleteConfirm()}
        </IonContent>

        {editVM.currentTabIndex === '0' && (
          <IonFab vertical='bottom' horizontal='end'>
            <IonFabButton
              color='tertiary'
              onClick={() => editVM.save()}
              disabled={!editVM.isValid}
              style={{
                marginTop: '-60px',
                marginLeft: '-60px',
              }}
            >
              <IonIcon icon={checkmarkSharp} />
            </IonFabButton>
          </IonFab>
        )}
        {/* {editVM.editingRecipeItem && <ItemNewModal itemsList={editVM.editingRecipeItem.itemsList} />} */}
        <RecipeItemEditModal />
      </>
    )
  }
}
