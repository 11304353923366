import React, { useEffect } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import './ForgotPasswordPage.scss'
import { UserStore } from '../user/store/UserStore'
import { AppStore } from '../stores/AppStore'
import ForgotPasswordForm from './ForgotPasswordForm'
import LogoHeader from '../shared/logo-header/LogoHeader'
import { match } from 'react-router'
import { ForgotPasswordVM } from './ForgotPasswordVM'
import { RootStore } from '../stores/RootStore'

interface Props {
  appStore: AppStore
  rootStore: RootStore
  match?: match
}

const ForgotPasswordPage: React.FC<Props> = ({ appStore, rootStore, match }) => {
  const vm = useLocalObservable(() => new ForgotPasswordVM(rootStore, match.params['email']))

  useEffect(() => {
    appStore.setCurrentRoute('/forgot-password')
    if (appStore.isLoggedIn) appStore.navigateTo('/')
    appStore.themeGenVM.loadTheme('system')
  }, [appStore])

  return (
    <IonPage id='ForgotPasswordPage'>
      <IonContent scrollY ref={(e) => appStore.applyScrollStyles(e)}>
        <LogoHeader />
        <ForgotPasswordForm vm={vm} />
      </IonContent>
    </IonPage>
  )
}

export default inject('appStore', 'userStore', 'rootStore')(observer(ForgotPasswordPage))
