import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { BoardInvitation } from '../../board-invitations/aggregate/BoardInvitation'
import agent from '../../Agent'

export class InviteCardVM {
  constructor(private rootStore: RootStore, private onInvitationChanged: (inv: BoardInvitation) => void) {
    makeObservable(this)
    this.loadBoardInvitation()
  }

  @observable public inviteCodeInputShown: boolean = false
  @observable public inviteCode: string = ''
  @observable public codeValidationTried: boolean = false
  @observable public isValidCode: boolean = false
  @observable public codeErrorMessages: string[] = []
  @observable public boardInvitation: BoardInvitation = null
  @observable public acceptBoardInvitation: boolean = false

  @action
  public toggleAcceptBoardInvitation() {
    this.acceptBoardInvitation = !this.acceptBoardInvitation
    if (!this.acceptBoardInvitation) {
      setTimeout(() => this.clearBoardInvitation(), 1000)
    }
  }

  @action
  public showInviteCodeInput() {
    this.inviteCodeInputShown = true
  }

  @action
  public hideInviteCodeInput() {
    this.inviteCodeInputShown = false
  }

  @action
  public setInviteCode(code: string) {
    if (!code) code = ''
    this.inviteCode = code.replace('-', '').toUpperCase().trim()
    this.clearCodeErrorMessages()
  }

  @computed
  private get inviteCodeCleaned() {
    return this.inviteCode.replace('-', '').toUpperCase().trim()
  }

  @computed
  public get inviteCodeFormatted() {
    const code = this.inviteCode
    if (code.length < 4) return code
    if (code.length === 4) return code.substring(0, 3) + '-' + code.substring(3, 4)
    if (code.length === 5) return code.substring(0, 3) + '-' + code.substring(3, 5)
    if (code.length === 6) return code.substring(0, 3) + '-' + code.substring(3, 6)
    return
  }

  @action
  public async validateInviteCode() {
    if (this.inviteCode === '') {
      this.clearCodeErrorMessages()
      this.addCodeErrorMessage('Please enter a code.')
      return
    }
    if (this.inviteCode.length < 6) {
      this.clearCodeErrorMessages()
      this.addCodeErrorMessage('Code must be 6 characters.')
      return
    }
    try {
      const result = await agent.BoardInvitations.checkcode({ code: this.inviteCodeCleaned })
      runInAction(() => {
        this.codeValidationTried = true
        this.isValidCode = result.IsSuccessful
        if (!this.isValidCode) {
          this.clearCodeErrorMessages()
          this.addCodeErrorMessage('Invalid code. Please try again.')
          return
        }
        this.acceptBoardInvitation = true
        this.setBoardInvitation(result.BoardInvitation)
        this.inviteCodeInputShown = false
      })
    } catch (err) {
      console.error(err)
      this.clearCodeErrorMessages()
      this.addCodeErrorMessage('Error validating code. Please try again.')
    }
  }

  @action
  private setBoardInvitation(inv: BoardInvitation) {
    this.boardInvitation = inv
    this.onInvitationChanged(inv)
  }

  @action
  private clearBoardInvitation() {
    this.boardInvitation = null
    this.onInvitationChanged(null)
  }

  @action
  public async loadBoardInvitation() {
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('inv')
    if (!id) return
    this.boardInvitation = await agent.BoardInvitations.get(id)
    if (this.boardInvitation) {
      runInAction(() => (this.acceptBoardInvitation = true))
    }
  }

  @action
  private clearCodeErrorMessages() {
    this.codeErrorMessages = []
  }

  @action
  private addCodeErrorMessage(msg: string) {
    this.codeErrorMessages.push(msg)
  }

  @computed
  public get hasInvitation() {
    return Boolean(this.boardInvitation)
  }

  @computed
  public get invitationFromName() {
    if (!this.boardInvitation) return ''
    return this.boardInvitation.FromName
  }

  @computed
  public get invitationBoardName() {
    if (!this.boardInvitation) return ''
    return this.boardInvitation.BoardName
  }
}
