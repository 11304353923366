import { observable, action, computed, makeObservable, runInAction } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import { BoardInvitation } from '../aggregate/BoardInvitation'
import { BoardInvitationsService } from '../service/BoardInvitationsService'
import { TextUtils } from '../../shared/TextUtils'
import { Clipboard } from '@capacitor/clipboard'

export class BoardInvitationNewModalVM {
  private emailInputEl: HTMLIonInputElement
  @observable private boardInvitation: BoardInvitation
  private focused: boolean = false

  constructor(private rootStore: RootStore) {
    makeObservable(this)
    runInAction(() => {
      this.boardInvitation = BoardInvitation.create(this.rootStore.boardsStore.currentBoard.BoardGuid)
    })
  }

  @observable public isOpen: boolean = false
  @observable public isProcessing: boolean = false
  @observable public copiedToastShown: boolean = false

  
  @action
  public copiedToastHidden() {
    this.copiedToastShown = false
  }

  @computed
  public get invitationCode(): string {
    return this.rootStore.boardsStore.currentBoard.InvitationCode
  }

  @computed
  public get toEmailAddress(): string {
    return this.boardInvitation.ToEmailAddress
  }

  @action
  public setToEmailAddress(val) {
    this.boardInvitation.setToEmailAddress(val)
  }

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  public focusEmailInput() {
    if (!this.emailInputEl) return
    this.emailInputEl.focus()
  }

  @action
  public async setEmailInput(e: HTMLIonInputElement) {
    if (this.focused) return
    setTimeout(() => e.setFocus(), 1000)
    this.focused = true
    TextUtils.disableAutoComplete(e)
  }

  
  @action
  public async copyCode() {
    try {
      await Clipboard.write({
        string: this.invitationCode.toString(),
      })
      runInAction(() => (this.copiedToastShown = true))
    } catch (e) {
      alert(e)
      console.error(e)
    }
  }

  @computed
  public get isValid(): boolean {
    if (!this.toEmailAddress) return false
    if (this.toEmailAddress === '') return false
    return true
  }

  public goBack() {
    this.rootStore.appStore.handleGoBack()
  }

  @action
  public save() {
    const svc = new BoardInvitationsService(this.rootStore)
    svc.save(this.boardInvitation.toDTO())
    this.goBack()
  }
}
