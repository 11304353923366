import React from 'react'
import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonText } from '@ionic/react'
import { chevronForwardOutline } from 'ionicons/icons'
import { SettingsListVM } from '../view-models/SettingsListVM'
import AppUpdateAvailableCard from '../../app-updates/views/AppUpdateAvailableCard'
import { MaterialCommunityIcons, MaterialIcons, Entypo, Feather, AntDesign } from 'react-web-vector-icons'
import { observer } from 'mobx-react'
import GuestUserCard from '../../user/views/guest-user/GuestUserCard'
import PanelCard from '../../shared/panel/PanelCard'

interface Props {
  vm: SettingsListVM
}

const SettingsList: React.FC<Props> = ({ vm }) => {
  const renderCaret = () => {
    if (!vm.isIos) return
    return <IonIcon icon={chevronForwardOutline} />
  }

  const renderBoardOptions = () => {
    return vm.boards.map((brd, idx) => (
      <IonItem
        onClick={() => vm.openBoard(brd.boardGuid)}
        key={'brd' + brd.boardGuid}
        className={brd.isCurrentBoard ? 'current-board-row' : ''}
      >
        <IonLabel>
          {brd.isCurrentBoard && <MaterialCommunityIcons name='check-circle' size={20} />}
          {!brd.isCurrentBoard && <MaterialCommunityIcons name='checkbox-blank-circle-outline' size={20} />}
          {brd.name}
        </IonLabel>
        {brd.isCurrentBoard && <IonText>Current</IonText>}
        {!brd.isCurrentBoard && <IonText>Switch</IonText>}
        &nbsp;
        {renderCaret()}
      </IonItem>
    ))
  }

  const rendeAllBoardsItems = () => {
    return (
      <>
        <IonListHeader>
          <IonLabel>All Boards</IonLabel>
        </IonListHeader>
        {renderBoardOptions()}
        <IonItem lines='full' onClick={() => vm.openNewBoardModal()}>
          <IonLabel>
            <AntDesign name='plus' size={20} />
            Create New Board
          </IonLabel>
          {renderCaret()}
        </IonItem>
        <IonItem lines='full' id='JoinBoardRow' onClick={() => vm.openJoinBoardModal()}>
          <IonLabel>
            <AntDesign name='link' size={20} />
            Join Board
          </IonLabel>
          {renderCaret()}
        </IonItem>
      </>
    )
  }

  const renderAppleIcon = () => {
    if (!vm.canSignInWithApple) return
    return <AntDesign name='apple1' size={20} />
  }

  const renderAccountItems = () => {
    return (
      <>
        <IonListHeader>
          <IonLabel>General</IonLabel>
        </IonListHeader>
        <IonItem onClick={() => vm.goTo('/accountsettings')}>
          <IonLabel>
            <AntDesign name='user' size={20} />
            Edit Account
          </IonLabel>
          <AntDesign name='google' size={20} />
          {renderAppleIcon()}
          {renderCaret()}
        </IonItem>
        <IonItem onClick={() => vm.goTo('/themeslist')}>
          <IonLabel>
            <MaterialIcons name='format-paint' size={20} />
            Theme
          </IonLabel>
          <IonText>{vm.themeMessage}</IonText>
          &nbsp;
          {renderCaret()}
        </IonItem>
        {/* <IonItem onClick={() => vm.goTo('/accountsettings')}>
          <IonLabel>
            <AntDesign name='google' size={20} />
            Google
          </IonLabel>
          <IonText>{vm.googleConnectedMessage}</IonText>
          &nbsp;
          {renderCaret()}
        </IonItem>
        <IonItem onClick={() => vm.goTo('/accountsettings')}>
          <IonLabel>
            <AntDesign name='apple1' size={20} />
            Apple
          </IonLabel>
          <IonText>{vm.appleConnectedMessage}</IonText>
          &nbsp;
          {renderCaret()}
        </IonItem> */}
        {/* <IonItem onClick={() => vm.logout()}>
          <IonLabel>
            <MaterialCommunityIcons name='logout' size={20} />
            Sign Out
          </IonLabel>
          {renderCaret()}
        </IonItem> */}
      </>
    )
  }

  const renderCurrentBoardItems = () => {
    return (
      <>
        <IonListHeader>
          <IonLabel>{vm.currentBoardName}</IonLabel>
        </IonListHeader>
        <IonItem onClick={() => vm.goTo('/boardsettings')}>
          <IonLabel>
            <AntDesign name='sharealt' size={20} />
            Invite Code
          </IonLabel>
          <IonText>{vm.currentBoardInvitationCode}</IonText>
          &nbsp;
          {renderCaret()}
        </IonItem>
        <IonItem onClick={() => vm.goTo('/invite')}>
          <IonLabel>
            <MaterialCommunityIcons name='email' size={20} />
            Invite by Email
          </IonLabel>
          &nbsp;
          {renderCaret()}
        </IonItem>
        <IonItem onClick={() => vm.goTo('/boardsettings')}>
          <IonLabel>
            <MaterialIcons name='people' size={20} />
            Manage Members
          </IonLabel>
          <IonText>{vm.currentBoardMembersCount}</IonText>
          &nbsp;
          {renderCaret()}
        </IonItem>
        <IonItem onClick={() => vm.goTo('/boardsettings')}>
          <IonLabel>
            <AntDesign name='edit' size={20} />
            Rename Board
          </IonLabel>
          {renderCaret()}
        </IonItem>
      </>
    )
  }

  const renderHelpItems = () => {
    return (
      <>
        <IonListHeader>
          <IonLabel>Help and Support</IonLabel>
        </IonListHeader>
        <IonItem onClick={() => vm.opneMobileRedirect()}>
          <IonLabel>
            <Entypo name='mobile' size={20} />
            Mobile Apps
          </IonLabel>
          {renderCaret()}
        </IonItem>
        <IonItem onClick={() => vm.openFAQ()}>
          <IonLabel>
            <AntDesign name='questioncircleo' size={20} />
            Frequently Asked Questions
          </IonLabel>
          {renderCaret()}
        </IonItem>
        <IonItem onClick={() => vm.openContact()}>
          <IonLabel>
            <Entypo name='light-bulb' size={20} />
            Suggest a Feature
          </IonLabel>
          {renderCaret()}
        </IonItem>
        <IonItem onClick={() => vm.openWebsite()}>
          <IonLabel>
            <Feather name='globe' size={20} />
            Visit Website
          </IonLabel>
          {renderCaret()}
        </IonItem>
        <IonItem onClick={() => vm.openContact()}>
          <IonLabel>
            <Entypo name='bug' size={20} />
            Report a Bug
          </IonLabel>
          {renderCaret()}
        </IonItem>
        {/* <IonItem onClick={() => vm.logout()}>
          <IonLabel>Leave a Review</IonLabel>
          {renderCaret()}
        </IonItem> */}
      </>
    )
  }

  const renderAppUpdate = () => {
    return <AppUpdateAvailableCard />
  }

  const renderGuestUserCard = () => {
    return <GuestUserCard />
  }

  const renderSignOutButton = () => {
    return (
      <IonButton color='danger' expand='full' onClick={() => vm.logout()} className='sign-out-button'>
        <IonLabel>
          <MaterialCommunityIcons name='logout' size={20} />
          Sign Out
        </IonLabel>
      </IonButton>
    )
  }

  return (
    <PanelCard>
      <IonList key={vm.key}>
        {renderAppUpdate()}
        {renderGuestUserCard()}
        {renderAccountItems()}
        {renderCurrentBoardItems()}
        {rendeAllBoardsItems()}
        {/* {renderSettingsItems()} */}
        {renderHelpItems()}
      </IonList>
      {renderSignOutButton()}
    </PanelCard>
  )
}

export default observer(SettingsList)
