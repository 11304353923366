import { persist } from 'mobx-persist'
import { observable, action, computed, makeObservable } from 'mobx'
import { UUIDUtils } from '../../utils/UUIDUtils'
import { Day } from './Day'
import { IMealCategoryDTO } from '../dtos/IMealCategoryDTO'
import { IDayDTO } from '../dtos/IDayDTO'
import moment from 'moment'
import { IVisibleUserDTO } from '../dtos/IVisibleUserDTO'
import { VisibleUser } from './VisibleUser'
import { serialize, deserialize, serializable, list, object } from 'serializr'

export class MealCategory implements IMealCategoryDTO {
  public static create(boardId: number, userId: number) {
    const cat = new MealCategory()
    cat.MealCategoryGuid = UUIDUtils.generateUUID()
    cat.BoardId = boardId
    cat.populateDays()
    cat.isNew = true
    cat.VisibleUsers.push(VisibleUser.create(userId, true))
    return cat
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public MealCategoryGuid: string = ''
  @serializable @observable public BoardId: number = 0
  @serializable @observable public Name: string = ''
  @serializable @observable public Rank: number = 1000
  @serializable @observable public SpecificTime: boolean = false
  @serializable @observable public Time: string = ''
  @serializable @observable public Color: string = ''
  @serializable(list(object(Day))) @observable public Days: Day[] = []
  @serializable(list(object(VisibleUser)))  @observable public VisibleUsers: VisibleUser[] = []
  @serializable @observable public IsDeleted: boolean = false
  public isOnServer: boolean = false
  public isNew: boolean = false

  @action
  public setName(val: string) {
    this.Name = val
  }

  @action
  public toggleSpecificTime() {
    this.SpecificTime = !this.SpecificTime
    if (!this.Time) this.Time = '12:00 pm'
  }

  @action
  public setRank(val: number) {
    this.Rank = val
  }

  @action
  public setColor(val: string) {
    this.Color = val
  }

  @action
  public toggleVisible(appUserId: number) {
    const found = this.VisibleUsers.find((e) => e.AppUserId === appUserId)
    if (found) found.toggleEnabled()
    else {
      const vu = VisibleUser.create(appUserId, true)
      this.VisibleUsers.push(vu)
    }
  }

  @computed
  public get Time12Hr(): string {
    if (this.Time === '') return ''
    if (!this.SpecificTime) return ''
    let val = moment(this.Time, 'H:mm').format('h:mma')
    val = val.replace(':00', '')
    val = val.replace('m', '')
    val = val.replace('p', '')
    return val
  }

  @action
  public setTime(val: string) {
    if (val === '') this.Time = ''
    if (val === '') return
    this.Time = val
  }

  public markIsNotOnServer(): void {
    this.isOnServer = false
  }

  @action
  private populateDays() {
    this.Days = []
    this.Days.push(Day.create('Monday', 1))
    this.Days.push(Day.create('Tuesday', 2))
    this.Days.push(Day.create('Wednesday', 3))
    this.Days.push(Day.create('Thursday', 4))
    this.Days.push(Day.create('Friday', 5))
    this.Days.push(Day.create('Saturday', 6))
    this.Days.push(Day.create('Sunday', 7))
  }

  public markIsOnServer(): void {
    this.isOnServer = true
  }

  public clone(): MealCategory {
    return deserialize(MealCategory, serialize(this))
  }

  public toDTO(): IMealCategoryDTO {
    return serialize(this)
  }
}
