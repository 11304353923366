import React from 'react'
import { IonItem, IonInput, IonButton, IonText, IonSpinner, IonIcon, IonCol, IonGrid, IonRow } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { AuthStore } from 'src/login/store/AuthStore'
import { eye, eyeOff } from 'ionicons/icons'
import { AntDesign, SimpleLineIcons, Feather, Entypo } from 'react-web-vector-icons'
import PanelCard from '../../shared/panel/PanelCard'
import ErrorMessages from '../../shared/error-messages/ErrorMessages'
import HelpFooter from '../../shared/help-footer/HelpFooter'
import InviteCard from '../../shared/invite-card/InviteCard'

interface Props {
  authStore?: AuthStore
}

const InputForm: React.FC<Props> = ({ authStore }) => {
  const { loginVM: vm } = authStore
  if (!vm) return

  const renderSpinner = () => {
    if (vm.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  const renderLoginWithEmailSpinner = () => {
    if (!vm.loginWithEmailSpinnerShown) return
    return renderSpinner()
  }

  const renderGoogleSpinner = () => {
    if (!vm.showGoogleSpinner) return
    return renderSpinner()
  }

  const renderAppleSpinner = () => {
    if (!vm.showAppleSpinner) return
    return renderSpinner()
  }

  const renderGuestSpinner = () => {
    if (!vm.showGuestSpinner) return
    return renderSpinner()
  }

  const renderContinueWithGoogle = () => {
    return (
      <IonButton
        color='dark'
        className='google-button'
        expand='full'
        type='button'
        onClick={() => vm.continueWithGoogle()}
      >
        <AntDesign name='google' size={20} />
        &nbsp; Continue with Google &nbsp;
        {renderGoogleSpinner()}
      </IonButton>
    )
  }

  const renderContinueWithApple = () => {
    if (!vm.canSignInWithApple) return
    return (
      <IonButton
        color='dark'
        className='apple-button'
        expand='full'
        type='button'
        onClick={() => vm.continueWithApple()}
      >
        <AntDesign name='apple1' size={20} />
        &nbsp; Continue with Apple &nbsp;
        {renderAppleSpinner()}
      </IonButton>
    )
  }

  const renderContinueAsGuest = () => {
    return (
      <IonButton
        color='medium'
        className='guest-button'
        expand='full'
        type='button'
        onClick={() => vm.continueAsGuest()}
      >
        <SimpleLineIcons name='ghost' size={20} />
        &nbsp; Continue as Guest &nbsp;
        {renderGuestSpinner()}
      </IonButton>
    )
  }

  const renderSignInWithEmail = () => {
    if (vm.loginWithEmailShown) return
    return (
      <IonButton
        color='medium'
        className='sign-in-with-email-button'
        expand='full'
        type='button'
        onClick={() => vm.showSignInWithEmail()}
      >
        <SimpleLineIcons name='envelope' size={20} />
        &nbsp; Sign In with Email &nbsp;
      </IonButton>
    )
  }

  const renderLoginButton = () => {
    return (
      <IonButton
        color='medium'
        disabled={vm.inputDisabled}
        className='login-button'
        expand='full'
        type='submit'
        onClick={() => vm.submit()}
      >
        <Entypo name='login' size={16} />
        &nbsp; Sign In &nbsp;
        {renderLoginWithEmailSpinner()}
      </IonButton>
    )
  }

  const renderSignInForm = () => {
    if (!vm.loginWithEmailShown) return
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault()
          vm.submit()
        }}
      >
        <IonGrid id='LoginForm'>
          <IonRow>
            <IonCol>
              <IonItem color='transparent' lines='none'>
                <IonInput
                  type='text'
                  placeholder='Email'
                  id='email'
                  name='email'
                  disabled={vm.inputDisabled}
                  onKeyDown={(e) => {
                    if (e.charCode === 13) vm.submit()
                  }}
                  value={vm.email}
                  onIonChange={(e: any) => vm.setEmail(e.target.value)}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem color='transparent' lines='none'>
                <IonInput
                  type={vm.passwordShown ? 'text' : 'password'}
                  id='password'
                  placeholder='Password'
                  name='password'
                  value={vm.password}
                  disabled={vm.inputDisabled}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') vm.submit()
                  }}
                  onIonChange={(e: any) => vm.setPassword(e.target.value)}
                />
                <IonButton
                  fill='clear'
                  className='toggle-password-btn'
                  onClick={() => vm.toggleShowPassword()}
                  tabIndex={-1}
                >
                  <IonIcon slot='end' icon={vm.passwordShown ? eyeOff : eye} />
                </IonButton>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{renderForgotPassword()}</IonCol>
            <IonCol>{renderLoginButton()}</IonCol>
          </IonRow>
        </IonGrid>
        <ErrorMessages messages={vm.errorMessages} />
      </form>
    )
  }

  const renderForgotPassword = () => {
    if (!vm.loginWithEmailShown) return
    return (
      <IonButton className='forgot-password-button' fill='clear' type='button' onClick={() => vm.goToForgotPassword()}>
        <Feather name='unlock' size={21} />
        &nbsp; Forgot Password?
      </IonButton>
    )
  }

  const renderRegisterButton = () => {
    return (
      <IonButton
        color='medium'
        className='register-button'
        expand='full'
        type='button'
        onClick={() => vm.goToRegister()}
      >
        <Feather name='send' size={20} />
        &nbsp; Register with Email
      </IonButton>
    )
  }

  return (
    <>
      <PanelCard centered maxWidth='600px'>
        <InviteCard onInvitationChanged={(inv) => vm.setBoardInvitation(inv)} />
        <IonGrid className='button-grid'>
          <IonRow>
            <IonCol sizeXs='12' sizeMd='6'>
              {renderContinueWithGoogle()}
            </IonCol>
            <IonCol sizeXs='12' sizeMd='6'>
              {renderContinueWithApple()}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{renderContinueAsGuest()}</IonCol>
          </IonRow>
        </IonGrid>
        <IonText className='or'>OR</IonText>
        <IonGrid className='button-grid'>
          <IonRow>
            <IonCol>{renderSignInWithEmail()}</IonCol>
          </IonRow>
        </IonGrid>
        {renderSignInForm()}
        <IonGrid className='button-grid'>
          <IonRow>
            <IonCol>{renderRegisterButton()}</IonCol>
          </IonRow>
        </IonGrid>
        <HelpFooter />
      </PanelCard>
    </>
  )
}

export default inject('authStore')(observer(InputForm))
