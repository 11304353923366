import React, { useEffect } from 'react'
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonBadge,
  IonSpinner,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonTitle,
  IonText,
  IonIcon,
} from '@ionic/react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { TextUtils } from '../../shared/TextUtils'
import WelcomeHeader from './WelcomeHeader'
import { RootStore } from '../../stores/RootStore'
import { RegisterVM } from '../view-models/RegisterVM'
import PanelCard from '../../shared/panel/PanelCard'
import { AntDesign } from 'react-web-vector-icons'
import { arrowForwardOutline, eye, eyeOff, logInOutline } from 'ionicons/icons'
import ErrorMessages from '../../shared/error-messages/ErrorMessages'
import HelpFooter from '../../shared/help-footer/HelpFooter'
import InviteCard from '../../shared/invite-card/InviteCard'

interface Props {
  rootStore?: RootStore
}

const RegisterForm: React.FC<Props> = ({ rootStore }) => {
  const vm = useLocalObservable(() => new RegisterVM(rootStore))

  useEffect(() => {
    return () => vm.dispose()
  }, [])

  const renderErrorMessages = () => {
    if (!vm.errorMessages) return
    return vm.errorMessages.map((msg, idx) => (
      <div className='error' key={'msg' + idx}>
        <IonBadge color='danger'>{msg}</IonBadge>
      </div>
    ))
  }

  const renderBoardInvitationInfo = () => {
    if (!vm.boardInvitation) return
    return (
      <IonCard className='board-info'>
        <IonCardContent>
          <strong>{vm.boardInvitation.FromName}</strong> has invited you to join the board &nbsp;
          <strong>{vm.boardInvitation.BoardName}</strong>
          <IonItem lines='none' onClick={() => vm.toggleAcceptBoardInvitation()}>
            <IonCheckbox checked={vm.acceptBoardInvitation} />
            &nbsp;&nbsp;Accept Invitation
          </IonItem>
        </IonCardContent>
      </IonCard>
    )
  }

  const renderSpinner = () => {
    if (!vm.showSpinner) return
    if (vm.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  const renderPassword = () => {
    if (vm.hasExternalAccount) return
    return (
      <IonItem>
        <IonLabel position='stacked'>Password</IonLabel>
        <IonInput
          id='password'
          name='sadfdasf'
          type={vm.passwordShown ? 'text' : 'password'}
          autocomplete='new-password'
          value={vm.password}
          onKeyPress={(e) => {
            if (e.charCode === 13) vm.submit()
          }}
          onIonChange={(e: any) => vm.setPassword(e.target.value)}
          ref={(e) => TextUtils.disableAutoComplete(e)}
        />
        <IonButton fill='clear' slot='end' onClick={() => vm.toggleShowPassword()} tabIndex={-1}>
          <IonIcon slot='end' icon={vm.passwordShown ? eyeOff : eye} />
        </IonButton>
      </IonItem>
    )
  }

  const renderPasswordConfirm = () => {
    if (vm.hasExternalAccount) return
    return (
      <IonItem>
        <IonLabel position='stacked'>Confirm Password</IonLabel>
        <IonInput
          type={vm.passwordShown ? 'text' : 'password'}
          autocomplete='new-password'
          id='password'
          name='sadfdasfdasf'
          value={vm.passwordConfirm}
          onKeyPress={(e) => {
            if (e.charCode === 13) vm.submit()
          }}
          onIonChange={(e: any) => vm.setPasswordConfirm(e.target.value)}
          ref={(e) => TextUtils.disableAutoComplete(e)}
        />
        <IonButton fill='clear' slot='end' onClick={() => vm.toggleShowPassword()} tabIndex={-1}>
          <IonIcon slot='end' icon={vm.passwordShown ? eyeOff : eye} />
        </IonButton>
      </IonItem>
    )
  }

  const renderEmail = () => {
    return (
      <IonItem>
        <IonLabel position='stacked'>Email</IonLabel>
        <IonInput
          type='text'
          id='email'
          value={vm.email}
          onKeyPress={(e) => {
            if (e.charCode === 13) vm.submit()
          }}
          onIonChange={(e: any) => vm.setEmail(e.target.value)}
        />
      </IonItem>
    )
  }

  const renderName = () => {
    return (
      <IonItem>
        <IonLabel position='stacked'>Name</IonLabel>
        <IonInput
          type='text'
          onKeyPress={(e) => {
            if (e.charCode === 13) vm.submit()
          }}
          value={vm.name}
          onIonChange={(e: any) => vm.setName(e.target.value)}
        />
      </IonItem>
    )
  }

  const renderGoogleSpinner = () => {
    if (!vm.isCheckingGoogleLogin) return
    if (vm.isIos) return <IonSpinner color='secondary' />
    return <IonSpinner name='crescent' color='secondary' />
  }

  const renderContinueWithGoogle = () => {
    if (vm.hasExternalAccount) return
    return (
      <IonButton
        color='dark'
        className='google-button'
        expand='full'
        type='button'
        onClick={() => vm.continueWithGoogle()}
      >
        <AntDesign name='google' size={20} />
        &nbsp; Continue with Google
        {renderGoogleSpinner()}
      </IonButton>
    )
  }

  const renderAppleSpinner = () => {
    if (!vm.isCheckingAppleLogin) return
    if (vm.isIos) return <IonSpinner color='secondary' />
    return <IonSpinner name='crescent' color='secondary' />
  }

  const renderContinueWithApple = () => {
    if (vm.hasExternalAccount) return
    if (!vm.canSignInWithApple) return
    return (
      <>
        <IonButton
          color='dark'
          className='apple-button'
          expand='full'
          type='button'
          onClick={() => vm.continueWithApple()}
        >
          <AntDesign name='apple1' size={20} />
          &nbsp; Continue with Apple
          {renderAppleSpinner()}
        </IonButton>
      </>
    )
  }

  const renderOr = () => {
    if (vm.hasExternalAccount) return
    return <IonTitle className='or'>OR</IonTitle>
  }

  const renderCreateButton = () => {
    return (
      <IonButton className='register-button' color='medium' expand='full' type='submit'>
        <IonIcon icon={arrowForwardOutline} slot='end' />
        Create Account &nbsp;
        {renderSpinner()}
      </IonButton>
    )
  }

  const renderFooter = () => {
    return (
      <div id='HaveAccountFooter'>
        <IonText className='header'>Already have an account?</IonText>
        <br />
        <IonButton color='medium' type='button' onClick={() => vm.goToLogin()}>
          <IonIcon icon={logInOutline} slot='start' />
          &nbsp; Sign in here
        </IonButton>
      </div>
    )
  }

  return (
    <PanelCard maxWidth='600px'>
      <WelcomeHeader vm={vm} />
      <form
        autoComplete='off'
        onSubmit={(e) => {
          e.preventDefault()
          vm.submit()
        }}
      >
        <InviteCard onInvitationChanged={(inv) => vm.setBoardInvitation(inv)} />
        <IonList>
          {renderEmail()}
          {renderName()}
          {renderPassword()}
          {renderPasswordConfirm()}
        </IonList>
        {renderCreateButton()}
        <ErrorMessages messages={vm.errorMessages} />
        {renderOr()}
        {renderContinueWithGoogle()}
        {renderContinueWithApple()}
      </form>
      {renderFooter()}
      <HelpFooter />
    </PanelCard>
  )
}

export default inject('rootStore')(observer(RegisterForm))
