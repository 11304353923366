import { computed } from 'mobx'
import { RootStore } from '../../stores/RootStore'

export class AccountMenuVM {
  constructor(private rootStore: RootStore) {}

  @computed
  private get user() {
    return this.rootStore.userStore.user
  }

  @computed
  public get isIos() {
    return this.rootStore.appStore.isIos
  }

  @computed
  public get userName() {
    if (!this.user) return ''
    if (!this.user.Name) return ''
    return this.user.Name
  }

  @computed
  public get accountMenuShown() {
    if (!this.user) return false
    if (!this.user.Name) return false
    return true
  }

  @computed
  public get notificationShown() {
    if (this.rootStore.appStore.hasAvailableAppUpdate) return true
    if (this.rootStore.userStore.isGuestUser) return true
    return false
  }

  public goToSettings() {
    this.rootStore.appStore.navigateTo('/settings')
  }
}
