import { VoiceRecorder, RecordingData, GenericResponse } from 'capacitor-voice-recorder'
import { StorageFilesWriteService } from './StorageFilesWriteService'
import { StorageFilesService } from './StorageFilesService'
import { RootStore } from '../../stores/RootStore'
import { IStorageFileDTO } from '../dtos/IStorageFileDTO'
import { StorageFile } from '../aggregate/StorageFile'
import { UUIDUtils } from '../../utils/UUIDUtils'

export class StorageFilesAudioService {
  constructor(private rootStore: RootStore) {}

  public async checkPermissions() {
    // try {
    //   const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    // } catch (e) {
    //   console.error('The following error occurred: ' + e)
    // }
    const canRecordAudio: GenericResponse = await VoiceRecorder.canDeviceVoiceRecord()
    // console.log('canRecord', canRecordAudio.value)
    if (!canRecordAudio.value) return
    const hasRecordingPermission: GenericResponse = await VoiceRecorder.hasAudioRecordingPermission()
    // console.log('hasPerm', hasRecordingPermission.value)
    if (hasRecordingPermission.value) return
    const requestPermission: GenericResponse = await VoiceRecorder.requestAudioRecordingPermission()
    // console.log('req', requestPermission.value)
  }

  public async startCapture() {
    /**
     * In case of success the promise will resolve to { value: true }
     * in case of an error the promise will reject with one of the following messages:
     * "MISSING_PERMISSION", "ALREADY_RECORDING", "MICROPHONE_BEING_USED", "DEVICE_CANNOT_VOICE_RECORD", or "FAILED_TO_RECORD"
     */
    try {
      const result2: GenericResponse = await VoiceRecorder.startRecording()
      console.log(result2.value)
    } catch (error) {
      console.error(error)
    }

    /**
     * will pause an ongoing recording. note that if the recording has not started yet the promise
     * will reject with `RECORDING_HAS_NOT_STARTED`. in case of success the promise will resolve to `{ value: true }` if the pause
     * was successful or `{ value: false }` if the recording is already paused.
     * if the current mobile os does not support this method the promise will reject with `NOT_SUPPORTED_OS_VERSION`
     */
    // VoiceRecorder.pauseRecording()
    //   .then((result: GenericResponse) => console.log(result.value))
    //   .catch((error) => console.log(error))

    /**
     * will resume a paused recording. note that if the recording has not started yet the promise
     * will reject with `RECORDING_HAS_NOT_STARTED`. in case of success the promise will resolve to `{ value: true }` if the resume
     * was successful or `{ value: false }` if the recording is already running.
     * if the current mobile os does not support this method the promise will reject with `NOT_SUPPORTED_OS_VERSION`
     */
    // VoiceRecorder.resumeRecording()
    //   .then((result: GenericResponse) => console.log(result.value))
    //   .catch((error) => console.log(error))

    /**
     * Will return the current status of the plugin.
     * in this example one of these possible values will be printed: "NONE" / "RECORDING" / "PAUSED"
     */
    // VoiceRecorder.getCurrentStatus()
    //   .then((result: CurrentRecordingStatus) => console.log(result.status))
    //   .catch((error) => console.log(error))
  }

  public async stopCapture(): Promise<IStorageFileDTO> {
    const result: RecordingData = await VoiceRecorder.stopRecording()
    const storageFile = StorageFile.create(this.rootStore.boardsStore.currentBoardId)
    let extension = 'wav'
    if (result.value.mimeType.includes('webm')) extension = 'webm'
    storageFile.setDuration(result.value.msDuration)
    storageFile.setFileName(UUIDUtils.generateUUID() + '.' + extension)
    storageFile.setMimeType(result.value.mimeType)
    const uploadSvc = new StorageFilesService(this.rootStore)
    const dto = await uploadSvc.uploadBase64(result.value.recordDataBase64, storageFile.toDTO())
    // const audioRef = new Audio(`data:${result.value.mimeType};base64,${result.value.recordDataBase64}`)
    // audioRef.oncanplaythrough = () => audioRef.play()
    // audioRef.load()
    return dto
  }
}
