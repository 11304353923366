import React from 'react'
import { IonTitle, IonPopover, IonList, IonItem, IonText } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { EvilIcons, MaterialCommunityIcons, Entypo, AntDesign } from 'react-web-vector-icons'
import BoardInvitationAcceptModal from 'src/board-invitations/views/BoardInvitationAcceptModal'
import { BoardsStore } from '../store/BoardsStore'
import BoardInvitationRow from './BoardInvitationRow'
import { BoardRowVM } from '../view-models/BoardRowVM'

interface Props {
  boardsStore?: BoardsStore
}

const BoardsMenu: React.FC<Props> = ({ boardsStore }) => {
  const { boardsMenuVM: vm } = boardsStore
  if (!vm) return null

  const handleAddBoardClick = () => {
    vm.startNewBoard()
  }

  const handleMenuButtonClick = (event) => {
    event.persist()
    vm.open(event)
  }

  const handleBoardClick = (brd: BoardRowVM) => {
    boardsStore.setCurrentBoard(brd.boardId)
    vm.close()
  }

  const renderBoardOptions = () => {
    return vm.boards.map((brd, idx) => (
      <IonItem
        lines='full'
        onClick={() => handleBoardClick(brd)}
        key={'brd' + idx}
        className={brd.isCurrentBoard ? 'current-board-row' : ''}
      >
        <MaterialCommunityIcons name='bulletin-board' size={16} />
        &nbsp; {brd.name}
      </IonItem>
    ))
  }

  const renderBoardInvitations = () => {
    return vm.boardInvitations.map((row) => <BoardInvitationRow row={row} key={row.key} />)
  }

  const renderInviteOption = () => {
    return (
      <IonItem lines='full' id='InviteRow' onClick={() => vm.invite()}>
        <AntDesign name='sharealt' size={20} />
        &nbsp; Invite
      </IonItem>
    )
  }

  const renderJoinBoard = () => {
    return (
      <IonItem lines='full' id='JoinBoardRow' onClick={() => vm.openJoinBoardModal()}>
        <Entypo name='shareable' size={14} />
        &nbsp; Join Board
      </IonItem>
    )
  }

  const renderInvitionsCountForNonIos = () => {
    if (vm.isIos) return
    if (vm.pendingBoardInvitations === 0) return
    return <div className='items-count'>{vm.pendingBoardInvitations}</div>
  }

  const renderInvitionsCountForIos = () => {
    if (!vm.isIos) return
    if (vm.pendingBoardInvitations === 0) return
    return <div className='items-count'>{vm.pendingBoardInvitations}</div>
  }

  const renderTitleForIos = () => {
    if (!vm.isIos) return
    return (
      <IonTitle unselectable='on'>
        {vm.currentBoardName}
        <EvilIcons name='chevron-down' color='white' size={18} />
        {renderInvitionsCountForIos()}
      </IonTitle>
    )
  }

  const renderTitleForNormal = () => {
    if (vm.isIos) return
    return (
      <div unselectable='on' className='normal'>
        <IonTitle>{vm.currentBoardName}</IonTitle>
        <EvilIcons name='chevron-down' color='white' size={18} />
        {renderInvitionsCountForIos()}
      </div>
    )
  }

  const renderSwitchBoardRow = () => {
    return (
      <IonItem lines='none' id='SwitchBoardRow'>
        <IonText>Boards</IonText>
      </IonItem>
    )
  }

  return (
    <div id='BoardsMenu'>
      <div onClick={(e) => handleMenuButtonClick(e)} className='title-menu'>
        {renderTitleForNormal()}
        {renderTitleForIos()}
        {renderInvitionsCountForNonIos()}
      </div>

      <IonPopover id='BoardsMenu_Content' isOpen={vm.isMenuOpen} event={event} onDidDismiss={() => vm.close()}>
        <IonList>
          {renderInviteOption()}
          {renderJoinBoard()}
          {renderSwitchBoardRow()}
          {renderBoardOptions()}
          {renderBoardInvitations()}
        </IonList>
      </IonPopover>

      <BoardInvitationAcceptModal />
    </div>
  )
}

export default inject('boardsStore')(observer(BoardsMenu))
