import React from 'react'
import {
  IonModal,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonButtons,
  IonIcon,
  IonBadge,
  IonToast,
} from '@ionic/react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { arrowBack, checkmark, eye, eyeOff } from 'ionicons/icons'
import { ChangePasswordModalVM } from '../../view-models/ChangePasswordModalVM'
import { RootStore } from '../../../stores/RootStore'
import ErrorMessages from '../../../shared/error-messages/ErrorMessages'

interface Props {
  rootStore?: RootStore
  onClose: () => void
}

const ChangePasswordModal: React.FC<Props> = ({ rootStore, onClose }) => {
  const vm = useLocalObservable(() => new ChangePasswordModalVM(rootStore, onClose))

  const renderButtons = () => {
    if (vm.isProcessing) return
    return (
      <div id='buttons'>
        <IonButton
          disabled={vm.isProcessing || vm.toastShown}
          type='button'
          size='small'
          color='secondary'
          onClick={onClose}
        >
          Cancel
        </IonButton>
        <IonButton tabIndex={1} type='submit' size='small' color='tertiary' disabled={vm.isProcessing || vm.toastShown}>
          Change Password
        </IonButton>
      </div>
    )
  }

  const renderToast = () => {
    return <IonToast isOpen={vm.toastShown} message='Password successfully changed' position='bottom' duration={1500} />
  }

  return (
    <IonModal isOpen onDidDismiss={onClose} id='ChangePasswordModal'>
      <IonHeader>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={onClose} tabIndex={-1}>
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Change your password</IonTitle>
          <IonButtons slot='end'>
            <IonButton
              type='submit'
              onClick={() => vm.handleSave()}
              tabIndex={-1}
              disabled={vm.isProcessing || vm.toastShown}
            >
              <IonIcon icon={checkmark} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form
          onSubmit={(e) => {
            vm.handleSave()
            e.preventDefault()
          }}
        >
          <IonList>
            <IonItem lines='inset'>
              <IonLabel position='stacked'>New Password</IonLabel>
              <IonInput
                ref={(e) => {
                  vm.setFocus(e)
                  vm.setTabIndex(e, 1)
                }}
                type={vm.passwordShown ? 'text' : 'password'}
                value={vm.newPassword}
                autocomplete='new-password'
                onIonChange={(e) => vm.setNewPassword(e.detail.value!)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') vm.handleSave()
                }}
              />
              <IonButton fill='clear' slot='end' onClick={() => vm.toggleShowPassword()} tabIndex={-1}>
                <IonIcon slot='end' icon={vm.passwordShown ? eyeOff : eye} />
              </IonButton>
            </IonItem>
            <IonItem lines='inset'>
              <IonLabel position='stacked'>Confirm New Password</IonLabel>
              <IonInput
                ref={(e) => vm.setTabIndex(e, 1)}
                type={vm.passwordShown ? 'text' : 'password'}
                autocomplete='new-password'
                value={vm.confirmPassword}
                onIonChange={(e) => vm.setConfirmPassword(e.detail.value!)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') vm.handleSave()
                }}
              />
              <IonButton fill='clear' slot='end' onClick={() => vm.toggleShowPassword()} tabIndex={-1}>
                <IonIcon slot='end' icon={vm.passwordShown ? eyeOff : eye} />
              </IonButton>
            </IonItem>
          </IonList>
          <ErrorMessages messages={vm.errorMessages} />
          {renderButtons()}
          {renderToast()}
        </form>
      </IonContent>
    </IonModal>
  )
}

export default inject('rootStore')(observer(ChangePasswordModal))
