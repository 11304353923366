import React, { useEffect } from 'react'
import { IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { AppStore } from '../../stores/AppStore'
import { RootStore } from '../../stores/RootStore'
import BackIcon from '../../shared/BackIcon'
import QRCode from '../../assets/images/GB_Store_QR_Code_Dark.png'

interface Props {
  appStore: AppStore
  rootStore: RootStore
}

const MobileAppsPage: React.FC<Props> = ({ appStore, rootStore }) => {
  useEffect(() => {
    appStore.setCurrentRoute('/mobileapps')
  }, [appStore])

  const renderBackButton = () => {
    return (
      <IonButtons slot='start'>
        <IonButton onClick={() => appStore.navigateTo('/settings')}>
          <BackIcon />
        </IonButton>
      </IonButtons>
    )
  }

  const renderGhostHeader = () => {
    if (!appStore.isSplitPaneVisible) return
    return (
      <IonHeader>
        <IonToolbar />
      </IonHeader>
    )
  }

  return (
    <IonPage id='MobileAppsPage'>
      {renderGhostHeader()}
      <IonHeader id='SettingsPage_Header'>
        <IonToolbar color='secondary'>
          {renderBackButton()}
          <IonTitle>Mobile Apps</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY ref={(e) => appStore.applyScrollStyles(e)}>
        <div id='LinksContainer'>
          <img src={QRCode} alt='QR-Code' id='QRCode' />
          <br />
          <a href='https://apps.apple.com/us/app/grocery-board/id1471128617'>
            <img
              id='AppStoreButton'
              src={'https://i0.wp.com/groceryboard.io/wp-content/uploads/2020/05/app-store.png?resize=230%2C72&ssl=1'}
              alt='App Store'
            />
          </a>
          <br />
          <a href='https://play.google.com/store/apps/details?id=com.groceryboard.app'>
            <img
              id='PlayStoreButton'
              src={'https://i0.wp.com/groceryboard.io/wp-content/uploads/2020/05/google-play.png?resize=230%2C72&ssl=1'}
              alt='Play Store'
            />
          </a>
          <br />
          <a href='https://apps.microsoft.com/store/detail/grocery-board/9PKT2R4Z8GBZ'>
            <img
              id='MSStoreButton'
              src={
                'https://i0.wp.com/groceryboard.io/wp-content/uploads/2021/01/English_get-it-from-MS.png?resize=300%2C108&ssl=1'
              }
              alt='Microsoft Store'
            />
          </a>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default inject('appStore', 'userStore', 'rootStore')(observer(MobileAppsPage))
