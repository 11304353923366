import { observable, action, makeObservable } from 'mobx'
import { AppleSignInResponse } from '../interfaces/AppleSignInResponse'
import { IStore } from '@elexient/elexiapp.bits.shared'
import { RootStore } from '../../stores/RootStore'
import { LoginVM } from '../view-models/LoginVM'
import { GoogleLoginResponse } from '@capgo/capacitor-social-login'

export class AuthStore implements IStore {
  private rootStore: RootStore

  constructor(rootStore) {
    makeObservable(this)
    this.rootStore = rootStore
    this.loginVM = new LoginVM(this.rootStore)
  }

  @observable public loginVM: LoginVM = null
  @observable public googleLoginInfo: GoogleLoginResponse = null
  @observable public appleLoginInfo: AppleSignInResponse = null
  @observable public appleLoginInfoUserName: string = ''

  @action
  public setGoogleLoginInfo(info: GoogleLoginResponse) {
    this.googleLoginInfo = info
  }

  @action
  public setAppleLoginInfo(info: AppleSignInResponse) {
    this.appleLoginInfo = info
    if (!info) return
    this.appleLoginInfoUserName = info.givenName + ' ' + info.familyName
  }

  @action
  public logout() {
    if (!this.rootStore.appStore.isHydrated) {
      setTimeout(() => this.logout(), 100)
      return
    }
    this.googleLoginInfo = null
    this.appleLoginInfo = null
    this.rootStore.clearData()
    this.rootStore.notificationsStore.unsubscribeFromAllTopics()
    // setTimeout(async () => await SocialLogin.logout({ provider: 'google' }), 2000)
  }

  public clearData() {}
}
