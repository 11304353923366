import React from 'react'
import { IonBadge } from '@ionic/react'
import './ErrorMessages.scss'
import { observer } from 'mobx-react'

interface Props {
  messages: string[]
}

const ErrorMessages: React.FC<Props> = ({ messages }) => {
  return (
    <div id='ErrorMessages'>
      {messages.map((msg, idx) => (
        <React.Fragment key={'errormsg' + idx}>
          <IonBadge color='danger'>{msg}</IonBadge>
          <br />
        </React.Fragment>
      ))}
    </div>
  )
}

export default observer(ErrorMessages)
