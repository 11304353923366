import React, { useEffect } from 'react'
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonPage,
  IonSpinner,
  IonIcon,
  IonTitle,
  IonText,
  IonToast,
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { AuthStore } from 'src/login/store/AuthStore'
import './RecoverAccountPage.scss'
import {
  arrowBack,
  chatbox,
  chatboxOutline,
  checkmark,
  checkmarkCircle,
  checkmarkCircleOutline,
  checkmarkCircleSharp,
  checkmarkSharp,
  help,
  helpBuoy,
} from 'ionicons/icons'
import { UserStore } from '../user/store/UserStore'
import { RootStore } from '../stores/RootStore'
import { RecoverAccountVM } from './RecoverAccountVM'
import ErrorMessages from '../shared/error-messages/ErrorMessages'
import HelpFooter from '../shared/help-footer/HelpFooter'
import LogoHeader from '../shared/logo-header/LogoHeader'
import PanelCard from '../shared/panel/PanelCard'

interface Props {
  appStore?: AppStore
  authStore?: AuthStore
  userStore?: UserStore
  rootStore?: RootStore
}

const RecoveryCodePage: React.FC<Props> = ({ appStore, authStore, rootStore, userStore }) => {
  const vm = useLocalObservable(() => new RecoverAccountVM(rootStore))

  useEffect(() => {
    authStore.logout()
    appStore.setCurrentRoute('/recover-account')
    appStore.themeGenVM.loadTheme('system')
  })

  if (!vm) return <IonSpinner />

  const renderToast = () => {
    if (!vm.toastShown) return
    return (
      <IonToast
        cssClass='success-toast'
        color='success'
        isOpen={vm.toastShown}
        message='Your code has been verified...'
        position='top'
        duration={3000}
        onDidDismiss={() => vm.goToResetPassword()}
        icon={checkmarkCircle}
      />
    )
  }

  const renderSpinner = () => {
    if (!vm.isProcessing) return
    if (vm.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  return (
    <IonPage id='RecoverAccountPage'>
      <IonContent scrollY ref={(e) => appStore.applyScrollStyles(e)}>
        <LogoHeader />
        <PanelCard maxWidth='600px'>
          <IonText className='title'>Account Recovery</IonText>
          <IonText>
            Please check your email for the <strong>Account Recovery Code</strong> we sent you.
            <br />
            Be sure to check your spam folder.
          </IonText>
          <form onSubmit={(e) => vm.tryCode(e)} autoComplete='off'>
            <div id='CodeInput'>
              <IonInput
                id='CodeInput'
                autofocus={true}
                type='number'
                placeholder='Enter Code'
                maxlength={6}
                disabled={vm.inputDisabled}
                value={vm.code}
                onIonChange={(e) => vm.setCode(e.detail.value)}
              />
            </div>
            <IonButton expand='full' color='medium' type='submit' disabled={vm.inputDisabled}>
              Submit
              <IonIcon icon={checkmarkSharp} slot='end' />
              &nbsp;
              {renderSpinner()}
            </IonButton>
          </form>
          {renderToast()}
          <ErrorMessages messages={vm.errorMessages} />
          <IonGrid id='OtherButtons'>
            <IonRow>
              <IonCol sizeXs='12' sizeMd='6'>
                <IonButton fill='clear' type='button' className='back-button' onClick={() => vm.goToLogin()}>
                  Back to Sign In
                  <IonIcon icon={arrowBack} slot='start' />
                </IonButton>
              </IonCol>
              <IonCol sizeXs='12' sizeMd='6'>
                <IonButton fill='clear' type='button' className='back-button' onClick={() => vm.goBack()}>
                  Didn't receive email?
                  <IonIcon icon={chatboxOutline} slot='start' />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          <HelpFooter />
        </PanelCard>
      </IonContent>
    </IonPage>
  )
}

export default inject('appStore', 'rootStore', 'userStore', 'authStore')(observer(RecoveryCodePage))
