import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { IonicSafeString } from '@ionic/core'
import { RootStore } from '../../stores/RootStore'
import { GuestUserMessages } from './guest-user/GuestUserMessages'

export class GuestUserToastVM {
  @observable private dismissed: boolean = false
  @observable private delayed: boolean = false

  constructor(private rootStore: RootStore) {
    makeObservable(this)
    setTimeout(() => runInAction(() => (this.delayed = true)), 1500)
  }

  @computed
  public get shown() {
    if (this.rootStore.appStore.currentRoute === '/guestuser') return false
    if (this.rootStore.appStore.currentRoute === '/settings') return false
    if (!this.rootStore.userStore.isGuestUser) return false
    if (this.dismissed) return false
    if (!this.delayed) return false
    return true
  }

  @action
  public goToRegister(): boolean | void | Promise<boolean | void> {
    this.dismissed = true
    this.rootStore.appStore.navigateTo('/guestuser')
  }

  @action
  public onDismiss(): void {
    this.dismissed = true
  }

  @computed
  public get message(): string | IonicSafeString {
    return GuestUserMessages.messageOptions[Math.floor(Math.random() * GuestUserMessages.messageOptions.length)]
  }
}
