import React from 'react'
import { inject, observer } from 'mobx-react'
import { RecipesStore } from '../../store/RecipesStore'
import { IonIcon, IonItem, IonLabel, IonTextarea, IonButton } from '@ionic/react'
import { add } from 'ionicons/icons'
import StepRow from './StepRow'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

interface Props {
  recipesStore?: RecipesStore
}

const StepsList: React.FC<Props> = ({ recipesStore }) => {
  const { editVM } = recipesStore
  if (!editVM) return null
  if (editVM.currentTabIndex !== '2') return null

  const renderNoSteps = () => {
    if (editVM.steps.length !== 0) return
    return (
      <IonItem className='no-steps'>
        <IonLabel>No Steps</IonLabel>
      </IonItem>
    )
  }

  const renderNewStepRow = () => {
    return (
      <IonItem onClick={() => editVM.addStep()} className='new-step-row'>
        <IonIcon color='tertiary' icon={add} />
        &nbsp;
        <IonLabel>Add</IonLabel>
      </IonItem>
    )
  }

  const SortableItem = SortableElement(({ e, index }) => {
    return <StepRow row={e} key={'st' + e.key} />
  })

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((e, index) => (
          <SortableItem disabled={false} key={'ric' + e.key} index={index} e={e} />
        ))}
      </div>
    )
  })

  return (
    <div id='StepsList' unselectable='on'>
      {renderNoSteps()}
      <SortableList
        hideSortableGhost
        useDragHandle
        helperClass='dragging'
        items={editVM.steps}
        onSortEnd={({ oldIndex, newIndex }) => editVM.reorderSteps(oldIndex, newIndex)}
      />
      {renderNewStepRow()}
    </div>
  )
}

export default inject('recipesStore')(observer(StepsList))
