import { observable, computed, action, makeObservable } from 'mobx'
import { UUIDUtils } from '../../utils/UUIDUtils'
import { IListItemDTO } from '../dtos/IListItemDTO'
import { serialize, deserialize, serializable, object } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'
import { Item } from './Item'

export class ListItem implements IListItemDTO, IAggregrate {
  public static create(boardId: number, itemGuid: string, listItemGuid: string = null) {
    if (boardId === 0) {
      console.error('boardid not set')
      throw 'board not set'
    }
    const li = new ListItem()
    li.ListItemGuid = listItemGuid
    if (!listItemGuid) {
      li.ListItemGuid = UUIDUtils.generateUUID()
      li.isNew = true
    }
    li.ItemGuid = itemGuid
    li.StatusId = 1
    li.BoardId = boardId
    return li
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public ItemGuid: string = ''
  @serializable @observable public ListItemGuid: string = ''
  @serializable @observable public Quantity: number = 0
  @serializable @observable public StatusId: number = 0
  @serializable @observable public BoardId: number = 0
  @serializable @observable public Notes: string = ''
  @serializable @observable public IsDeleted: boolean = false
  @serializable @observable public CreatedDateTime: string = ''
  @serializable @observable public CreatedByUserId: number = 0
  @serializable @observable public LastUpdatedDateTime: string = ''
  @serializable @observable public LastUpdatdByUserId: number = 0
  @serializable(object(Item)) @observable public Item: Item = undefined
  @serializable public isNew: boolean = false
  public isOnServer: boolean = false

  @computed
  public get isGotten(): boolean {
    return this.StatusId === 2
  }

  @computed
  public get hasNotes(): boolean {
    return this.Notes && this.Notes !== ''
  }

  @computed
  public get isCleared(): boolean {
    return this.StatusId === 3
  }

  public markIsOnServer() {
    this.isOnServer = true
  }

  public markIsNotOnServer() {
    this.isOnServer = false
  }

  @action
  public increaseQuantity(qty: number = undefined) {
    if (this.isCleared) this.Quantity = 0
    if (qty) this.Quantity = qty
    else this.Quantity += 1
    this.markAsPending()
  }

  @action
  public decreaseQuantity() {
    this.Quantity -= 1
  }

  @action
  public setQuantity(val) {
    this.Quantity = Number(val)
  }

  @action
  public setNotes(val) {
    this.Notes = val
  }

  @action
  public markAsPending() {
    this.StatusId = 1
  }

  @action
  public markAsGotten() {
    this.StatusId = 2
  }

  @action
  public markAsCleared() {
    this.StatusId = 3
  }

  @action
  public toggleGotten() {
    if (this.StatusId === 1) this.markAsGotten()
    else this.markAsPending()
  }

  public shouldSkipUpdate(fromItem: IListItemDTO): boolean {
    let changedVal = undefined
    if (!fromItem.Item) {
      // console.log('no fromitem')
      return true
    }
    if (!this.Item) {
      // console.log('no thisitem')
      return true
    }
    if (fromItem.Item.Name !== this.Item.Name) {
      changedVal = 'Name'
    }
    if (fromItem.Item.CategoryGuid !== this.Item.CategoryGuid) {
      changedVal = 'CategoryGuid'
    }
    if (fromItem.Item.Category?.Name !== this.Item.Category?.Name) {
      changedVal = 'CategoryName'
    }
    if (fromItem.Item.Category?.Color !== this.Item.Category?.Color) {
      changedVal = 'CategoryColor'
    }    if (fromItem.Item.Notes !== this.Item.Notes) {
      changedVal = 'Notes'
    }
    if (fromItem.Quantity !== this.Quantity) {
      changedVal = 'Quantity'
    }
    if (fromItem.IsDeleted !== this.IsDeleted) {
      changedVal = 'IsDeleted'
    }
    if (fromItem.StatusId !== this.StatusId) {
      changedVal = 'StatusId'
    }
    if (fromItem.Notes !== this.Notes) {
      changedVal = 'Notes'
    }
    if (this.lastUpdatedDateTimeAsDate >= new Date(fromItem.LastUpdatedDateTime)) {
      return true
    }
    if (changedVal) {
      // console.log('changed', changedVal)
      return false
    }
    return true
  }

  public get lastUpdatedDateTimeAsDate(): Date {
    return new Date(this.LastUpdatedDateTime)
  }

  public clone(): ListItem {
    return deserialize(ListItem, serialize(this))
  }

  public toDTO(): IListItemDTO {
    return serialize(this)
  }
}
