import React from 'react'
import { observer } from 'mobx-react'
import { IonButton, IonIcon, IonRippleEffect } from '@ionic/react'
import { add } from 'ionicons/icons'
import { ItemNewRowVM } from '../../view-models/list/ItemNewRowVM'

interface Props {
  row: ItemNewRowVM
  style: any
  index: number
}

const ItemNewRow: React.FC<Props> = ({ row, style }) => {
  return (
    <div style={style} id='ItemNewRow' onClick={() => row.openNewItemModal()}>
      <div className='btn ion-activatable'>
        <IonRippleEffect type='unbounded' />
        <IonIcon icon={add} size='small' />
        <div>{row.displayName}</div>
      </div>
      <div className='filler'></div>
    </div>
  )
}

export default observer(ItemNewRow)
