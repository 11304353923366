import React, { Component, lazy, Suspense } from 'react'
import { IonApp } from '@ionic/react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import '@ionic/core/css/core.css'
import '@ionic/core/css/ionic.bundle.css'
import LoginPage from './login/views/LoginPage'
import Layout from './layout/Layout'
import RegisterPage from './register/views/RegisterPage'
import ForgotPasswordPage from './forgot-password/ForgotPasswordPage'
import ResetPasswordPage from './reset-password/ResetPasswordPage'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import './theme/theme.scss'
import RecoverAccountPage from './recover-account/RecoverAccountPage'

class App extends Component<any, any> {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <div id='app'>
            <IonApp>
              <Route path={'/login'} component={LoginPage} exact />
              <Route path={'/register'} component={RegisterPage} exact />
              <Route path={'/forgot-password/:email'} component={ForgotPasswordPage} exact />
              <Route path={'/forgot-password'} component={ForgotPasswordPage} exact />
              <Route path={'/recover-account'} component={RecoverAccountPage} exact />
              <Route path={'/tno/:token'} component={ResetPasswordPage} exact />
              <Route path={'/'} component={Layout} />
            </IonApp>
          </div>
        </Router>
      </MuiPickersUtilsProvider>
    )
  }
}

export default App
