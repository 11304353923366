import { observable, action, runInAction, computed, makeObservable } from 'mobx'
import agent from '../../Agent'
import { User } from '../aggregate/User'
import { RootStore } from '../../stores/RootStore'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import { Device } from '@capacitor/device'
import { App } from '@capacitor/app'
import { IUserInfoResult } from '../interfaces/IUserInfoResult'

export class UserStore {
  private rootStore: RootStore

  constructor(rootStore) {
    makeObservable(this)
    makePersistable(this, { name: 'UserStore', properties: ['user'] }).then((st) => {
      let isHydrated = false
      if (process.env.NODE_ENV === 'test') isHydrated = true
      if (st && st.isHydrated) isHydrated = true
      if (isHydrated) this.onHydrationCompleted()
    })
    this.rootStore = rootStore
  }

  public onHydrationCompleted() {}

  @observable public user: User = undefined

  @action
  public async loadUser() {
    const deviceInfo = await this.getDeviceInfo()
    const appInfo = await this.getAppInfo()
    try {
      const response: IUserInfoResult = await agent.Users.getInfoV3({ deviceInfo, appInfo })
      if (response) {
        runInAction(() => {
          this.updateUserFromServer(response.AppUser)
          this.rootStore.featuresStore.setFeatures(response.Features)
          this.rootStore.appStore.setToken(response.Token)
          this.rootStore.appStore.setHasUpdate(response.HasUpdate)
        })
      }
    } catch (e) {
      console.log(JSON.stringify(e))
      if (JSON.stringify(e).includes(':401')) {
        this.rootStore.authStore.logout()
        this.rootStore.appStore.history.push('/login')
      }
    }
  }

  @computed
  public get isGuestUser() {
    return this.user?.EmailAddress.indexOf('guestuser') > -1
  }

  private async getDeviceInfo() {
    try {
      let info = await Device.getInfo()
      const id = await Device.getId()
      return { ...info, id }
    } catch (e) {
      return { error: JSON.stringify(e) }
    }
  }

  private async getAppInfo() {
    try {
      const info = await App.getInfo()
      return info
    } catch (e) {
      return { error: JSON.stringify(e) }
    }
  }

  private updateUserFromServer(fromUser) {
    this.user = deserialize(User, fromUser)
    this.rootStore.appStore.firebaseSvc.setUserInfo(fromUser.IdentityId, fromUser.EmailAddress, fromUser.Name)
    return this.user
  }

  public clearData() {
    this.user = new User()
  }
}
