import { observable, action, computed, runInAction, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import agent from '../../Agent'
import { BoardInvitation } from '../aggregate/BoardInvitation'

export class BoardInvitationEditVM {
  private rootStore: RootStore
  private boardInvitation: BoardInvitation

  constructor(rootStore: RootStore, boardInvitation: BoardInvitation) {
    makeObservable(this)
    this.rootStore = rootStore
    this.boardInvitation = boardInvitation
  }

  @observable public isModalShown: boolean = true
  @observable public isProcessing: boolean = false

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  @computed
  public get fromName(): string {
    return this.boardInvitation.FromName
  }

  @computed
  public get boardName(): string {
    return this.boardInvitation.BoardName
  }

  @action
  public toggle() {
    this.isModalShown = !this.isModalShown
  }

  @action
  private hideBoardsMenu() {
    this.rootStore.boardsStore.boardsMenuVM.close()
  }

  @action
  public async accept() {
    try {
      this.isProcessing = true
      const postData = { BoardInvitationGuid: this.boardInvitation.BoardInvitationGuid }
      const result = await agent.BoardInvitations.accept(postData)
      if (result.IsSuccessful) {
        await this.rootStore.boardInvitationsStore.loadData()
        await this.rootStore.loadData('board invitation accepted')
        this.rootStore.boardsStore.setCurrentBoardFromNewBoardGuid(this.boardInvitation.BoardGuid)
        this.isModalShown = false
        this.hideBoardsMenu()
      } else {
        alert('Error accepting invitation')
      }
      this.isProcessing = false
    } catch (e) {
      alert('Error accepting invitation')
    }
  }

  @action
  public async decline() {
    try {
      this.isProcessing = true
      const postData = { BoardInvitationGuid: this.boardInvitation.BoardInvitationGuid }
      const result = await agent.BoardInvitations.decline(postData)
      if (result.IsSuccessful) {
        await this.rootStore.boardInvitationsStore.loadData()
        // await this.rootStore.loadData('board invitation declined')
        this.isModalShown = false
        this.hideBoardsMenu()
      } else {
        alert('Error declining invitation')
      }
      this.isProcessing = false
    } catch (e) {
      alert('Error declining invitation')
    }
  }
}
