import { observable, action, computed, makeObservable, runInAction, set } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import agent from '../Agent'

export class ForgotPasswordVM {
  constructor(private rootStore: RootStore, email: string) {
    makeObservable(this)
    this.rootStore = rootStore
    this.setEmail(decodeURIComponent(email))
    if (!this.email) this.loadSavedLoginEmail()
  }

  @observable public email: string = ''
  @observable public errorMessages: Array<string> = []
  @observable public isProcessing: boolean = false
  @observable public toastShown: boolean = false

  @action
  private loadSavedLoginEmail() {
    if (!this.rootStore.appStore.loginEmailAddress) {
      setTimeout(() => this.loadSavedLoginEmail(), 50)
      return
    }
    this.email = this.rootStore.appStore.loginEmailAddress
  }

  public get sendButtonDisabled(): boolean {
    return this.isProcessing || this.toastShown
  }

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  @computed
  public get isValid() {
    if (this.email === '') return false
    return true
  }

  @action
  public setEmail(val) {
    if (!val) return
    if (val === 'undefined') return
    this.email = val
    this.clearErrorMessages()
    this.rootStore.appStore.setLoginEmailAddress(val)
  }

  @action
  private clearErrorMessages() {
    this.errorMessages = []
  }

  public goBack() {
    this.rootStore.appStore.navigateTo('/login')
  }

  public goToRecoverAccount() {
    this.rootStore.appStore.navigateTo('/recover-account')
  }

  @action
  private showToast() {
    this.toastShown = true
  }

  @action
  private validate() {
    this.errorMessages = []
    if (this.email === '') {
      this.errorMessages.push('Please enter a valid email address')
    }
    return this.errorMessages.length === 0
  }

  @action
  private markAsNotProcessing() {
    this.isProcessing = false
  }

  @action
  public async save() {
    if (!this.validate()) return
    this.isProcessing = true
    const email = document.getElementById('email2').getElementsByTagName('input')[0].value
    this.setEmail(email)
    this.errorMessages = []
    const form = {
      Email: this.email,
    }
    const success = await this.saveOnServer(form)
    if (!success) {
      this.markAsNotProcessing()
      return
    }
    this.showToast()
  }

  private async saveOnServer(form) {
    try {
      const results: Array<string> = await agent.Users.forgotPassword(form)
      if (results[0] === 'Success' && results.length === 1) {
        return true
      } else {
        results.forEach((e) => {
          this.errorMessages.push(e)
        })
      }
    } catch (e) {
      console.error(e)
    }
    return false
  }
}
