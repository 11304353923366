import React from 'react'
import { IonButton, IonCard, IonCardContent, IonCheckbox, IonInput, IonItem, IonLabel } from '@ionic/react'
import { InviteCardVM } from './InviteCardVM'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Foundation } from 'react-web-vector-icons'
import { RootStore } from '../../stores/RootStore'
import ErrorMessages from '../error-messages/ErrorMessages'
import './InviteCard.scss'

interface Props {
  rootStore?: RootStore
  onInvitationChanged: (inv) => void
}

const HelpFooter: React.FC<Props> = ({ rootStore, onInvitationChanged }) => {
  const vm = useLocalObservable(() => new InviteCardVM(rootStore, onInvitationChanged))

  const renderHaveInviteCode = () => {
    if (vm.inviteCodeInputShown) return
    if (vm.hasInvitation) return
    return (
      <IonButton
        className='have-invite-code-button'
        fill='clear'
        type='button'
        onClick={() => vm.showInviteCodeInput()}
      >
        <Foundation name='key' size={20} />
        &nbsp; Have an Invite Code?
      </IonButton>
    )
  }

  const renderBoardInvitationInfo = () => {
    if (!vm.hasInvitation) return
    return (
      <IonCard id='AcceptBoardInvitation'>
        <IonCardContent>
          <IonItem lines='none' onClick={() => vm.toggleAcceptBoardInvitation()}>
            <IonCheckbox color='tertiary' checked={vm.acceptBoardInvitation} />
            <IonLabel>
              Accept the invitation to join the board <strong>{vm.invitationBoardName}</strong>
            </IonLabel>
          </IonItem>
        </IonCardContent>
      </IonCard>
    )
  }

  const renderInviteCodeInput = () => {
    if (!vm.inviteCodeInputShown) return
    return (
      <>
        <div id='InvitationCodeInput'>
          <IonInput
            name='inviteCode'
            value={vm.inviteCodeFormatted}
            placeholder='Code'
            autofocus
            maxlength={7}
            onKeyDown={(e) => {
              if (e.key === 'Enter') vm.validateInviteCode()
            }}
            onIonChange={(e: any) => {
              vm.setInviteCode(e.target.value)
            }}
          />
          <div id='Buttons'>
            <IonButton fill='clear' onClick={() => vm.validateInviteCode()} tabIndex={-1}>
              <div slot='end'>
                <FontAwesomeIcon icon={faCheck} />
              </div>
            </IonButton>
            <IonButton fill='clear' onClick={() => vm.hideInviteCodeInput()} tabIndex={-1}>
              <div slot='end'>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </IonButton>
          </div>
        </div>
        <ErrorMessages messages={vm.codeErrorMessages} />
      </>
    )
  }

  return (
    <div id='InviteCard'>
      {renderBoardInvitationInfo()}
      {renderHaveInviteCode()}
      {renderInviteCodeInput()}
    </div>
  )
}

export default inject('rootStore')(observer(HelpFooter))
