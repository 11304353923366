import React from 'react'
import { inject, observer, useLocalObservable } from 'mobx-react'
import { RootStore } from '../../stores/RootStore'
import { NativeButtonVM } from './NativeButtonVM'
import PlayStoreButton from '../../assets/images/google-play.png'
import AppStoreButton from '../../assets/images/app-store.png'
import './NativeButton.scss'
import { IonCol, IonGrid, IonRow } from '@ionic/react'

interface Props {
  rootStore?: RootStore
}

const NativeButton: React.FC<Props> = ({ rootStore }) => {
  const vm = useLocalObservable(() => new NativeButtonVM(rootStore))

  const renderAppStoreButton = () => {
    if (!vm.appStoreButtonShown) return
    return (
      <IonCol sizeXs={vm.buttonMdWidth} id='AppStoreButton' className={vm.bothButtonsShown ? 'bothButtons' : ''}>
        <img src={AppStoreButton} alt='App Store' onClick={() => vm.redirectToAppStore()} />
      </IonCol>
    )
  }

  const renderPlayStoreButton = () => {
    if (!vm.playStoreButtonShown) return
    return (
      <IonCol sizeXs={vm.buttonMdWidth} id='PlayStoreButton' className={vm.bothButtonsShown ? 'bothButtons' : ''}>
        <img src={PlayStoreButton} alt='Play Store' onClick={() => vm.redirectToPlayStore()} />
      </IonCol>
    )
  }

  return (
    <div id='NativeButton'>
      <IonGrid>
        <IonRow>
          {/* <IonCol sizeXs={vm.spacerMdWidth} /> */}
          {renderPlayStoreButton()}
          {renderAppStoreButton()}
          {/* <IonCol sizeXs={vm.spacerMdWidth} /> */}
        </IonRow>
      </IonGrid>
    </div>
  )
}

export default inject('rootStore')(observer(NativeButton))
