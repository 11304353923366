import React from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { inject } from 'mobx-react'
import LoginForm from './LoginForm'
import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { AuthStore } from '../store/AuthStore'
import { AppStore } from '../../stores/AppStore'
import LogoHeader from '../../shared/logo-header/LogoHeader'
import NativeButton from '../../shared/native-button/NativeButton'
import '../styles/LoginPage.scss'

interface Props {
  appStore: AppStore
  authStore: AuthStore
  history: any
}

@inject('appStore')
@inject('authStore')
export default class LoginPage extends React.Component<Props, any> {
  componentDidMount() {
    if (Capacitor.getPlatform() !== 'web') SplashScreen.hide()
    this.props.appStore.setCurrentRoute('/login')
    if (this.props.appStore.isLoggedIn) {
      this.props.history.push('/')
    }
    this.props.appStore.themeGenVM.loadTheme('system')
  }

  render() {
    return (
      <IonPage id='LoginPage'>
        <IonContent scrollY ref={(e) => this.props.appStore.applyScrollStyles(e)}>
          <LogoHeader />
          <NativeButton />
          <LoginForm />
        </IonContent>
      </IonPage>
    )
  }
}
